.login-form {

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, .25);
        z-index: 100;

        .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background-color: $color-white;
            width: min(50rem, 100%);
            height: min(35rem, 100%);
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;

            p {
                text-align: center;
            }

            svg {
                color: $color-black !important;
                transform: scale(1.2);
            }
        }
    }

    .big-btn {
        margin-bottom: 3.2rem;

        @include media('<sm') {
            margin-bottom: 1.6rem;
        }
    }

    .form-prompt {
        display: block;
        color: $color-black;
        @include body-13;
        font-family: $font-roboto;
        margin-bottom: 1rem;

        span {
            text-decoration: underline;
        }
    }

    .form-prompt:last-of-type {
        margin-bottom: 4rem;

        @include media('<sm') {
            margin-bottom: 2rem;
        }
    }

    .separator {
        display: flex;
        align-items: center;
        @include body-15;
        gap: 1.1rem;
        margin-bottom: 4.1rem;

        span {
            flex: 1;
            height: 1px;
            background-color: $color-black;
        }

        @include media('<sm') {
            margin-bottom: 2.05rem;
        }
    }

    .logo-btn:last-of-type {
        margin-bottom: 4rem;

        @include media('<sm') {
            margin-bottom: 2rem;
        }
    }

    .project-version {
        text-decoration: underline;
        @include body-10;
    }
}