@import '../../../utility/project/_olia-variables.scss';

.project-login.-olia {

    p {
        font-family: $font-olia-neue-haas-grotesk;
    }

    .login-container {
        background-color: $color-white;
        padding-top: 6.5rem;
        padding-bottom: 0;
        overflow: hidden;
        position: relative;

        .content-container {
            position: relative;
            z-index: 1;
            justify-content: flex-start;

            .project-group {
                .logo {
                    width: 17.9rem;
                    height: 5.8rem;
                }
            }
        }
    }

    .inline-input {

        input {
            font-family: $font-olia-neue-haas-grotesk;
            font-weight: 500;

            &::placeholder {
                opacity: 1;
                color: $color-black;
            }
        }

        p {
            font-family: $font-olia-neue-haas-grotesk;
            font-weight: 300;
        }
    }


    .big-btn {
        background-color: $color-olia-red;
        color: $color-white;
        font-family: $font-olia-neue-haas-grotesk;
        font-weight: 500;
        border-radius: 10rem;
        letter-spacing: 0.08rem;
    }

    .separator {
        color: $color-black;
        font-family: $font-olia-neue-haas-grotesk;

        span {
            background-color: $color-black;
        }
    }

    .logo-btn {
        font-weight: 500;
        font-family: $font-olia-neue-haas-grotesk;
        background-color: $color-white;
        border-radius: 0;
        font-size: 1.2rem;
        letter-spacing: .075rem;
        border: 1px solid $color-black;
        border-radius: 10rem;
        transition: all 0.35s ease-in-out;

        @include can-hover {
            background-color: $color-black;
            color: $color-white;
        }
    }

    .slider-container {
        flex: 1 0 60%;
        position: relative;
        overflow: hidden;

        .logo-container {
            position: absolute;
            right: 2.7rem;
            bottom: 2.3rem;
            width: 15rem;
            height: 3.3rem;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        @include media('<lg') {
            flex-basis: 40%;
        }

        @include media('<sm') {
            flex-basis: 25%;
        }

        img,
        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}