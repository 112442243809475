.single-contact-view-component {
    width: 100%;
    overflow: auto;

    .top-container {
        width: 100%;
        padding-top: 4rem;
        padding-bottom: 2rem;
        padding-inline: 4.3rem;
        margin-top: -2px;
        background-color: $color-light-blue;
        border-bottom: 1px solid $color-border-gray;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        .meta-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1.5rem;

            >.link-container {
                display: flex;
                align-items: center;
                gap: 1.25rem;

                .linkout {
                    svg {
                        width: 3rem;
                        height: auto;
                        aspect-ratio: 1/1;
                    }
                }
            }

            >.group-right {
                .text {
                    @include title-20;
                }
            }
        }


        .user-details {
            padding: 1.5rem 4.3rem;

            >.text {
                span {
                    font-weight: bold;
                }
            }
        }

        .filter-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            background-color: $color-light-blue;

            >.text {
                @include title-15;

                span {
                    color: $color-success;
                    @include title-20;
                }
            }

            >.options {
                display: flex;
                gap: 2rem;

                .text {
                    white-space: nowrap;
                }

                select {
                    width: 100%;
                    padding-right: 1.25rem;
                    color: $color-black;
                    background-color: $color-light-blue;
                    border: 1px solid $color-black;
                    outline: none;
                    margin-bottom: 1.5rem;
                    @include body-15;

                    @include media('<sm') {
                        margin-bottom: 1rem;
                    }

                    &.has-error {
                        margin-bottom: 0;
                    }

                    &::placeholder {
                        color: rgb(0, 0, 0, .5);
                    }

                    &:disabled {
                        opacity: .55;
                        pointer-events: none !important;
                    }
                }
            }
        }
    }

    .events-container {


        .scroll-list {

            >.list-container {

                >.list-item:first-of-type {
                    padding-top: 1.5rem;
                }

                >.list-item {
                    padding: 1.25rem 4.3rem;
                    border-bottom: 1px solid $color-border-gray;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

}