.global-contact-view-component {
    width: 100%;
    overflow: auto;

    .search-group {
        padding-inline: 4.3rem;
        padding-top: 4.2rem;
        padding-bottom: 2rem;
        margin-bottom: 3rem;
        margin-top: -2px;
        width: 100%;
        display: block;
        background-color: $color-light-blue;
        position: relative;
        z-index: 50;

        input {
            margin-left: 2rem;
            padding: 1rem 2.0rem;
            min-width: 27rem;
            border: none;
            outline: none;
        }
    }

    .list-element:first-of-type {
        padding-top: 0;
    }

    .list-element {
        padding: 2.5rem 4.3rem;
        border-bottom: 1px solid $color-border-gray;

        .details {
            margin-bottom: 1rem;
        }

        .projects {
            margin-bottom: 1rem;
        }

        .events {}

        .project-btn {
            margin-top: 1.5rem;
            width: fit-content;
            @include body-15;
        }


        span {
            font-weight: 500;
        }

    }
}