@import '../../utility/project/olia-variables';

.main-nav.-olia {
    background-color: $color-white;
    color: $color-black;
    padding: 0;
    max-height: unset;
    min-height: 5.5rem;
    max-height: 7rem;
    height: fit-content;
    padding: .8rem 0;

    >.wrapper {
        padding-left: 2.6rem;
        max-height: inherit;

        @include media('<md') {
            padding-inline: 1.9rem;
            height: fit-content !important;
        }
    }

    .logo {
        width: 8.2rem;
        height: 5.51rem;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .links-container {
        justify-content: flex-end;
        padding-right: 3.5rem;

        @include media('<sm') {
            padding-right: 1.9rem;
        }

        @include media('<lg') {}

        .link {
            @include can-hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                background-color: $color-black;
            }
        }

        button {
            font-family: $font-olia-neue-haas-grotesk;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.8rem;
            margin-right: 5.2rem;
            color: inherit;

            @include media('<lg') {
                font-size: 1.2rem;
                margin-right: 3rem;
            }

            &.-btn {
                background: $color-black;
                color: $color-white;
                border: 1px solid transparent;

                &:hover {
                    background: $color-white;
                    color: $color-black;
                    border: 1px solid $color-black;
                }

                &::after {}
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -.2rem;
                transform: translateX(-50%);
                height: 2px;
                width: 0;
                transition: all .3s ease-out;
                background-color: $color-black;
            }

            &.-active {
                &::after {
                    width: 100%;
                }
            }

            &:hover {
                &::after {}
            }
        }

        button:last-child {
            margin-right: 0;
        }

        a:last-of-type {}
    }

    .links-container-mobile {

        @include media('<=md') {}

        .logout-mobile {
            font-family: $font-olia-neue-haas-grotesk;
            font-weight: normal;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
        }
    }

    .buttons-group {

        button {}
    }

    .lang-btn {}

    .notification-btn {}

}