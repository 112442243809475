.toast {

    background-color: #1C1C1C;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3rem;
    will-change: transform;
    gap: 1.2rem;

    .group {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .close-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .message {
        position: relative;
        max-width: 40rem;
        min-width: 25rem;

        span {
            display: inline-block;
            font-weight: 500;
        }
    }

    &.-success {
        border: 1px solid $color-success;

        .background {
            fill: $color-success;
        }

        .icon {
            stroke: $color-black;
        }

        .close-icon {
            stroke: $color-success;
        }
    }

    &.-error {
        border: 1px solid $color-error;

        .background {
            fill: $color-error;
        }

        .close-icon {
            stroke: $color-error;
        }
    }

    &.-warning {
        border: 1px solid $color-warning;

        .background {
            fill: $color-warning;
        }

        .close-icon {
            stroke: $color-warning;
        }
    }

    &.-info {
        border: 1px solid $color-info;

        .background {
            fill: $color-info;
        }

        .close-icon {
            stroke: $color-info;
        }
    }
}