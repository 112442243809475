.main-nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 1.4rem 0;
    height: 100%;
    max-height: 5.5rem;
    background-color: $color-black;
    color: $color-white;
    z-index: 100;


    >.wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        // align-items: center;
    }

    .logo {
        a {
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }

    .links-container {
        display: flex;
        align-items: center;

        @include media('<=md') {
            display: none;
        }

        a,
        button:last-of-type {
            margin-right: 0;
        }

        a,
        button {
            @include label-15;
            font-weight: 400;
            position: relative;
            display: inline-block;
            margin-right: 3.5rem;
            color: $color-white;

            &.-btn {
                background-color: $color-white;
                color: $color-black;
                border: 1px solid $color-white;
                padding: .5rem 1.2rem;
                transition: all .3s ease-out;

                &:hover {
                    background-color: transparent;
                    color: $color-white;
                }

                &::after {
                    display: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -.2rem;
                transform: translateX(-50%);
                height: 2px;
                width: 0;
                transition: all .3s ease-out;
                background-color: $color-white;
            }

            &.-active {
                &::after {
                    width: 100%;
                }
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }

        a:last-of-type {
            margin-right: 1.4rem;
        }
    }

    .links-container-mobile {
        display: none;

        @include media('<=md') {
            display: flex;
            align-items: center;
        }

        .logout-mobile {
            @include label-15;
            position: relative;
            display: inline-block;
            color: $color-white;
            background-color: $color-white;
            color: $color-black;
            border: 1px solid $color-white;
            padding: .5rem 1.2rem;
            transition: all .3s ease-out;
        }
    }

    .buttons-group {
        display: flex;
        align-items: center;
        gap: .8rem;

        button {
            margin-right: 0;
        }
    }

    .lang-btn {
        border-radius: 50%;
        background-color: $color-yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        aspect-ratio: 1/1;
    }

    .notification-btn {
        border-radius: 50%;
        background-color: $color-light-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.3rem;
        aspect-ratio: 1/1;
    }
}