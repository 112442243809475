@import '../../../utility/project/mayfair-west-variables';

.project-login.-mayfair {

    p {
        font-family: $font-agenda;
    }

    .login-container {

        padding-top: 6.5rem;
        padding-bottom: 0;

        .content-container {
            justify-content: flex-start;

            .project-group {
                .logo {
                    width: 15.2rem;
                    height: 4.8rem;
                }
            }
        }

    }

    .inline-input {

        input {
            font-family: $font-agenda;
            font-weight: 500;

            &::placeholder {
                opacity: 1;
                color: $color-black;
            }
        }

        p {
            font-family: $font-agenda;
            font-weight: 300;
        }
    }


    .big-btn {
        background-color: $color-mayfair-pink;
        font-family: $font-agenda;
        font-weight: 500;
    }

    .logo-btn {
        font-weight: 500;
        font-family: $font-agenda;
    }

    .slider-container {
        flex: 1 0 60%;
        position: relative;

        @include media('<lg') {
            flex-basis: 40%;
        }

        @include media('<sm') {
            flex-basis: 25%;
        }

        img,
        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}