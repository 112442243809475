*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    font-size: calc(100vw / 1440 * 10);

    line-height: normal;

    @include media('<xl') {
        font-size: calc(100vw / 1200 * 10);
    }

    @include media('<lg') {
        font-size: calc(100vw / 992 * 10);
    }

    @include media('<md') {
        font-size: calc(100vw / 768 * 10);
    }

    @include media('<sm') {
        font-size: calc(100vw / 418 * 10);
    }
}

td ~ .ios-red{

}

body {
    background-color: $color-light-blue;
    line-height: normal;
    @include body-15;

    h1 {
        @include body-15;
    }
}

.inline-padding {
    padding-inline: 2.6rem;

    @include media('<md') {}

    @include media('<sm') {
        padding-inline: 1.9rem;
    }
}

.nav-padding {
    padding-top: 5.5rem;

    @include media('<=md') {
        padding-bottom: 5.5rem;
    }
}

.text-success {
    color: $color-success;
}