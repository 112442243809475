.project-page {
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    // global declarations
    h2 {
        @include title-25;
    }

    h3 {
        @include title-20;
    }

    .title {
        margin-bottom: 3.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

    }

    .sub-title {
        margin-bottom: 2rem;
    }

    section {
        position: relative;
        padding-top: 4.5rem;
        padding-bottom: 8rem;
        border-bottom: 1px solid $color-black;

        @include media('<md') {
            padding-top: 2.5rem;
            padding-bottom: 4rem;
        }
    }

    section:last-of-type {
        border-bottom: 0;
        // padding-bottom: 0;
    }

    .section-download-btn {
        position: absolute;
        right: 2.6rem;
        top: 4.5rem;
        display: flex;
        align-items: center;
        @include label-15;
        background-color: $color-aquamarine;
        color: $color-black;
        text-transform: uppercase;
        padding: .5rem 1rem;
        gap: .8rem;
        border: 1px solid transparent;
        transition: all .3s ease-out;
        cursor: pointer;

        .icon {
            display: flex;
            align-items: center;
        }

        &:hover {
            background-color: transparent;
            border: 1px solid $color-black;
        }

        @include media('<md') {}

        @include media('<sm') {
            right: 1.9rem;
        }
    }

    .placeholder-card {
        height: 27.2rem;
        width: 100%;
        background-color: $color-white;
    }
}

.project-page .landing {
    background-color: $color-black;
    color: $color-white;
    padding: 0;
    border: none;

    .-desktop {
        @include media('<md') {
            display: none;
        }

        .content-left {
            padding-top: 2.6rem;
            padding-bottom: 7.1rem;

            .wrapper {
                padding-inline: 2rem;
            }

            .greeting {
                display: flex;
                align-items: center;
                margin-bottom: 7.7rem;
                gap: 1.5rem;

                svg,
                img {
                    width: 6.4rem;
                    height: auto;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                }

                .text-group {
                    h2 {
                        @include title-35;

                        @include media('<md') {
                            @include title-25;
                        }

                    }

                    p {
                        @include title-15;
                        line-height: 2.25rem;
                    }
                }
            }

            .presentation-centre {
                display: flex;
                width: 100%;
                gap: 1.5rem;
                margin-bottom: 3rem;

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .project-blurb {
                max-width: 50.8rem;
                margin-bottom: 4.5rem;
                white-space: pre-line;
                text-align: justify;
                @include body-15-medium;
            }
        }

        .content-right {

            .media {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: inline-block;
                    vertical-align: baseline;
                }

                @include media('<md') {
                    min-height: 22.1rem;
                    aspect-ratio: 43/22;
                }
            }
        }
    }

    .-mobile {
        display: none;

        @include media('<md') {
            display: block;
        }

        .content-top {
            padding-top: 5.1rem;

            .greeting {
                display: flex;
                gap: 1.6rem;
                margin-bottom: 3.7rem;
                padding-inline: 2rem;

                .text-group {

                    h2 {
                        font-family: $font-poppins;
                        font-size: 1.8rem;
                        line-height: 2.1rem;
                    }

                    p {
                        font-family: $font-poppins;
                        font-size: 1.2rem;
                        line-height: 1.4rem;
                    }
                }

                svg,
                img {
                    width: 4.2rem;
                    height: auto;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                }
            }

            .media {
                position: relative;
                width: 100%;
                height: auto;
                aspect-ratio: 430/ 221;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .content-bottom {
            padding-top: 2rem;
            padding-inline: 2rem;
            padding-bottom: 3rem;

            .presentation-centre {
                margin-bottom: 3rem;
            }

            .project-blurb {
                // margin-bottom: 4.5rem;
                white-space: pre-line;
                text-align: justify;
            }

            .project-download-all-btn {
                margin-top: 4.5rem;
            }
        }
    }

}

.project-page .brand {

    .pdf-viewer-container {
        row-gap: 4rem;
    }

    .brand-card {

        @include media('<lg') {
            margin-bottom: 3.5rem;
        }

        .media {
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            margin-bottom: 3.1rem;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .content-group {
            .title {
                margin-bottom: .8rem;
                @include body-15-semb;
            }

            .content {
                margin-bottom: 1.3rem;
                min-height: 4.6rem;
            }
        }

        .btns-container {
            display: flex;
            gap: 1.2rem;
        }
    }
}

.project-page .media-kit {

    .accordion-container {
        .single-project-accordion:nth-of-type(1) {
            margin-top: 5rem;
        }

        .single-project-accordion:last-of-type {
            .wrapper {
                border: none;
            }
        }
    }


}

.project-page .floorplans {}

.project-page .offering {
    .table-container {
        padding-top: 5rem;
    }

    table {
        display: block;
        margin-bottom: 3rem;
    }


    th,
    td {
        display: table-cell;
        border: 1px solid $color-black;
        padding: 1rem 2rem;
    }

    th {
        padding: 1rem 2rem;
        @include body-20-medium;

        @include media('<md') {
            @include body-15-medium
        }
    }

    .accordions-container {}
}

.project-page .contact {
    .contact-card {
        display: flex;
        align-items: center;
        padding: 3.65rem 0;
        border-bottom: 1px solid $color-grey;

        @include media('<md') {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1rem;
        }

        .media {
            width: 30rem;
            height: auto;
            aspect-ratio: 1/1;

            @include media('<sm') {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 1.5rem;
            height: 100%;
            padding: 5rem 6rem;

            &.-no-image {
                padding-left: 0;
            }

            a:hover {
                text-decoration: underline;
            }

            @include media('<md') {
                padding: 1rem 0;
                align-items: flex-start;
            }
        }
    }
}