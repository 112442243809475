.embla {
    overflow: hidden;
    height: 100%;
    width: 100%;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.embla__container {
    display: flex;
    width: inherit;
    height: inherit;
}

.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    width: 100%;
    height: 100%;
}

.embla__dots {}