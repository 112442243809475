$color-white: #fff;
$color-black: #060606;
$color-light-blue: #F0F2F5;
$color-light-gray: #F4F4F4;
$color-yellow: #FBBC05;
$color-pink: #FF00F5;
$color-aquamarine: #80FFE7;
$color-grey: #D9D9D9;
$color-success: #4A936B;
$color-error: #CA4237;
$color-red: #FF0000;
$color-info: #207CCE;
$color-warning: #F0A405;
$color-magic-link: #2400FF;
$color-border-gray: #D6D5C4;

// fonts
$font-roboto: var(--font-roboto);
$font-poppins: var(--font-poppins);
$font-test: 'Bw Modelica SS01';