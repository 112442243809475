// colors
$color-avenue-orange: #FA4616;
$color-avenue-white: #FFFFFF;
$color-avenue-black: #000000;
$color-avenue-brown-gray: #382F2D;
$color-avenue-gray: #F3F2EF;
$color-avenue-zorba: #A59C94;
$color-avenue-masala: #4c4442;
$color-avenue-disabled: #DEDDDB;
$color-avenue-dark-brown: #382F2D;

// fonts
$font-gt-super-display: 'GT Super Display';
$font-gt-super-text-italic: 'GT Super Text';
$font-gt-super-text-book: 'GT Super Text Book';
$font-gt-super-ds-md: 'GT Super Ds Trial Md';
$font-gt-super-display-super: 'GT Super Display Super';

/* regular 400 | bold 700 */
$font-termina: 'termina';

/* regular 400 | bold 700 */
$font-ff-scala-sans-pro: 'ff-scala-sans-pro';

/* regular 400 | bold 700 */
$font-barlow: 'barlow';

/* light = 300 | regular = normal | medium = 500 | bold = bold */
$font-inter: 'Inter';