.not-found {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: $color-black;
    color: $color-white;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        min-height: inherit;
        padding-left: 13.6rem;
        padding-right: 13.9rem;
    }

    .flex-group-top {
        padding-top: 3.7rem;
    }

    .logo {
        margin-bottom: 8.2rem;

        svg {
            fill: $color-aquamarine;
        }
    }

    h1 {
        @include title-55;
    }

    h2 {
        @include title-25;
    }

    .title-container {
        margin-bottom: 11.7rem;
    }

    .actions-container {
        display: flex;
        gap: 4rem;

        .links {
            display: flex;
            gap: 1.8rem;

            .link {
                padding: .5rem 2.2rem;
                background-color: transparent;
                border: 1px solid $color-white;
                height: fit-content;
                transition: all .3s ease-out;

                &.-light {
                    background-color: $color-white;
                    color: $color-black;

                    &:hover {
                        background-color: transparent;
                        color: $color-white;
                    }
                }

                &:hover {
                    background-color: $color-white;
                    color: $color-black;
                }
            }
        }

        p {
            @include body-12;
            max-width: 38.8rem;
        }

    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        @include body-12;
        padding-bottom: 7.2rem;

        p:nth-child(1) {
            max-width: 38.8rem;
        }

        p:nth-child(2) {
            text-align: right;
            max-width: 38.8rem;
        }
    }

}