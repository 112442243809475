.single-project-accordion {

    .wrapper {
        border-bottom: 1px solid $color-black;
        padding: 1.6rem 2.6rem 1.6rem 0;

        @include media('<md') {
            padding-right: unset;
        }

        &[datatype-open="true"] {
            .trigger {
                svg {
                    // transform: rotate(180deg);
                    transform: scaleY(-1);
                }
            }
        }
    }

    .trigger {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 2.5rem;
        @include label-15;

        svg {
            transition: all .45s ease-out;
        }
    }

    .panel {
        height: 0;
        max-height: 0;
        overflow: hidden;
        @include body-15;

        .media {
            height: 35rem;
            max-width: 85rem;
            aspect-ratio: 1/1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .text {
            max-width: 60rem;
            margin-bottom: 1.2rem;
            white-space: pre-line;
        }

        .point-content {
            list-style: inside;
            padding-left: 2rem;
            text-indent: -2rem;
        }

        .buttons-container {
            display: flex;
            gap: .8rem;
        }


        &.-open {
            height: auto;
            max-height: 100%;
            overflow: visible;
            padding: 1.6rem 2.6rem 1.6rem 0;
        }
    }
}