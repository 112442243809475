.floorplan-sorter .filter-btn {

    padding: .45rem 1.05rem;
    background-color: $color-white;
    color: $color-black;
    transition: all .3s ease-out;

    &.-active {
        background-color: $color-black;
        color: $color-white;
    }
}

.floorplan-sorter .filters-container {
    display: flex;
    gap: 1.6rem;
    margin-bottom: 2.7rem;

    @include media('<md') {
        flex-direction: column;
    }

    .container-group {
        label {
            display: inline-block;
            margin-bottom: 1.6rem;
            @include body-15-medium;
        }

        button {
            text-transform: capitalize;
        }
    }

    .btns {
        display: flex;
        flex-wrap: wrap;
        gap: .8rem;
    }
}

.floorplan-sorter .floorplan-card {
    background-color: $color-white;
    padding: 2.8rem 3.35rem;
    user-select: none;

    .details-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.2rem;

        .details {
            @include body-15-medium;
            text-transform: capitalize
        }

        .type {
            @include title-25;
            text-transform: capitalize;
            min-height: 7.8rem;
            max-width: 11rem;
        }
    }

    .media {
        position: relative;
        width: 100%;
        height: auto;
        max-height: 25.3rem;
        aspect-ratio: 1/1;
        margin-bottom: 1.5rem;

        @include media('<md') {
            max-height: unset;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .btns-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
}

.floorplan-sorter .no-results {
    @include title-30;
    text-align: center;
    margin: 3.2rem 0;
}