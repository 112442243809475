.project-login {
    width: 100%;
    height: 100dvh;

    .wrapper {
        height: inherit;
        width: 100%;
        display: flex;

        @include media('<xl') {
            flex-direction: column;
        }
    }

    .login-container {
        flex: 1 0 35%;
        background-color: $color-white;
        padding-inline: 8.6rem;
        padding-top: 4.8rem;
        padding-bottom: 1.6rem;
        overflow-y: auto;
        width: 100%;

        .content-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5rem;
        }

        @include media('<xl') {
            order: 2;
            flex: 1 0 auto;
            padding-inline: 0;

            .content-container {
                width: 100%;
                max-width: 80rem;
                height: 100%;
                margin-inline: auto;
            }
        }

        @include media('<md') {
            padding-inline: 4.3rem;
            padding-top: 1.6rem;

            .content-container {
                max-width: unset;
                margin-inline: unset;
            }
        }

        .project-group {
            display: flex;
            align-items: center;
            gap: 2rem;

            .logo {
                position: relative;
                width: 8.2rem;
                height: auto;
                aspect-ratio: 1/1;

                svg,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .project {
                @include title-25;
            }

            .developer {
                @include title-15;
            }
        }
    }

    .slider-container {
        flex: 0 1 auto;
        min-height: 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .embla__slide {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}