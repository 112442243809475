@import '../../../utility/project/avenue-variables';

@mixin download-all-btn-avenue {
    left: unset;
    top: unset;
    right: unset;
    position: relative;
    background-color: $color-avenue-orange;
    color: $color-white;
    width: fit-content;
    gap: 1.5rem;
    padding: .8rem 4rem;
    padding-top: 1rem;
    font-family: $font-termina;
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 1.2rem;
    border: 1px solid transparent;

    svg {
        width: 1.5rem;
        height: auto;
        aspect-ratio: 1/1;

        path {
            stroke: $color-white;
            transition: all .5s ease-out;
        }
    }

    @include can-hover {
        border: 1px solid $color-avenue-orange;
        background-color: transparent;
        color: $color-black;

        svg {
            path {
                stroke: $color-avenue-orange;
            }
        }
    }

}

.project-page.avenue {

    &.nav-padding {
        @include media('<md') {
            padding-top: 5.5rem;
            padding-bottom: 0;
        }
    }

    .project-btn {
        background-color: $color-avenue-orange;
        color: $color-white;
        outline: none;
        border: none;
        font-family: $font-termina;
        font-size: 1.15rem;
        text-align: center;
        width: fit-content;
        font-weight: 500;
        padding: .8rem 4rem;
        padding-top: 1rem;
        text-transform: uppercase;
        border: 1px solid transparent;

        &.-small {
            padding: .8rem 1.5rem;
            padding-top: 1rem;
        }

        @include can-hover {
            background-color: transparent;
            color: $color-black;
            border: 1px solid $color-avenue-orange;
        }
    }

    &.nav-padding {
        padding-top: 7rem;
    }

    h2 {}

    h3 {}

    .validation-container {
        width: 100dvw;
        height: 100dvh;
        @include flex-center;

        h1 {
            font-family: $font-termina;
            font-size: 5rem;

            @include media('<md') {
                font-size: 3rem;
            }
        }
    }


    .sub-title {}

    section {
        background-color: $color-white;
        border-bottom: 0;

        >.title {
            font-family: $font-gt-super-ds-md;
            font-size: 3.5rem;
            font-weight: 100;
            line-height: 4.2rem;
            padding-top: 5.5rem;
            padding-bottom: 3.5rem;
        }
    }

    section:last-of-type {}

    .section-download-btn {}

    .placeholder-card {}

    .landing {
        background-color: $color-white;
        color: $color-black;

        .-desktop {

            .content-left {
                padding-top: 7.8rem;

                .title {
                    font-family: $font-gt-super-ds-md;
                    font-size: 3.5rem;
                    line-height: 4.2rem;
                    text-transform: uppercase;
                    white-space: pre-line;
                }

                .wrapper {}

                .greeting {

                    .text-group {
                        h2 {
                            font-family: $font-gt-super-ds-md;
                            font-size: 3.5rem;
                            line-height: 4.2rem;
                            margin-bottom: 1.5rem;
                        }

                        p {
                            font-family: $font-ff-scala-sans-pro;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            font-weight: 300;
                            margin-bottom: 4.3rem;
                        }
                    }
                }

                .presentation-centre {

                    a {}
                }

                .project-blurb {
                    font-family: $font-ff-scala-sans-pro;
                    font-size: 1.5rem;
                    line-height: 2.625rem;
                    font-weight: 400;
                }
            }

            .content-right {

                .media {}
            }
        }

        .-mobile {


            .content-top {
                padding-bottom: 3.5rem;

                .title {
                    padding-inline: 2rem;
                    font-family: $font-gt-super-ds-md;
                    font-size: 3.5rem;
                    line-height: 4.2rem;
                    text-transform: uppercase;
                    white-space: pre-line;
                }

                .greeting {
                    .text-group {
                        h2 {
                            font-family: $font-gt-super-ds-md;
                            font-size: 3.5rem;
                            line-height: 4.2rem;
                            margin-bottom: 1.5rem;
                        }

                        p {
                            font-family: $font-ff-scala-sans-pro;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            font-weight: 300;
                            margin-bottom: 4.3rem;
                            max-width: 40rem;
                        }
                    }
                }
            }

            .content-bottom {

                .presentation-centre {}

                .project-blurb {}

                .project-download-all-btn {}
            }
        }
    }

    // Anchor Overview
    .overview {
        padding-top: 4.4rem;
        padding-bottom: 3.7rem;
        border-bottom: 1px solid $color-black;

        @include media('<md') {
            padding-top: 0;
        }

        >.title {
            width: 100%;
            border-bottom: 1px solid $color-black;
        }

        .overview-container {
            row-gap: 3rem;
        }

        .overview-details-container {
            border-bottom: 1px solid $color-black;
            padding-bottom: 8.8rem;

            @include media('<md') {
                padding-bottom: 0;
            }

            .overview-detail {
                @include media('<sm') {
                    margin-bottom: 4rem;
                }

                .title {
                    font-family: $font-termina;
                    font-weight: 400;
                    line-height: 1.8rem;
                    font-size: 1.5rem;
                    letter-spacing: .1rem;
                }

                .description {
                    font-family: $font-termina;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }
            }
        }

        .overview-brand-story {
            padding-top: 3.7rem;

            >.wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-bottom: 5.6rem;

                @include media('<md') {
                    flex-direction: column;
                    padding-bottom: 0;
                }
            }

            h2 {
                font-family: $font-termina;
                font-size: 3.5rem;
                font-weight: 300;
                line-height: 4.2rem;
                flex: 1;
            }

            p {
                flex: 1;
                font-family: $font-termina;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .overview-media-container {}
    }

    // ANCHOR Overview
    .overview {
        padding-bottom: 5.3rem;

        >.title-container {
            display: flex;
            margin-bottom: 5.3rem;

            @include media('<sm') {
                flex-direction: column;
            }

            @include media('<md') {
                margin-bottom: 0;
            }

            h2 {
                flex: 1;
                font-family: $font-gt-super-ds-md;
                font-weight: 500;
                font-size: 3.5rem;
                line-height: 4.2rem;
            }

            .blurb {
                font-family: $font-termina;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .container-fluid {
            display: flex;
            flex-direction: column;
        }

        .section-download-btn {
            @include download-all-btn-avenue();
            margin-bottom: 7.5rem;

            @include media('<sm') {
                margin-bottom: 0;
            }
        }

        .overview-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-family: $font-termina;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: .2rem;
                max-width: 33rem;
            }

            .content {
                font-family: $font-ff-scala-sans-pro;
                font-size: 1.5rem;
                line-height: 2rem;
                margin-bottom: 2rem;
                max-width: 30rem;

                @include media('<lg') {
                    max-width: 45rem;
                }
            }

            .links {
                display: flex;
                gap: .7rem;
                width: 100%;

                .project-btn {
                    width: 100%;
                    justify-content: center;
                    max-width: 30rem;

                    @include media('<lg') {
                        max-width: 45rem;
                    }
                }

            }
        }
    }

    // ANCHOR Social & Photography
    .social,
    .photography {
        padding-bottom: 0;

        .title {
            font-family: $font-gt-super-ds-md;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        // any title that isn't the first one

        .sub-title {
            font-family: $font-termina;
            font-size: 1.5rem;
            line-height: 2.4rem;
            letter-spacing: .2rem;
            font-weight: 400;
            margin-bottom: 1.5rem;
        }

        .description {
            p {
                white-space: pre-line;
                font-family: $font-ff-scala-sans-pro;
                font-size: 1.5rem;
                line-height: 1rem;

                @include media('<md') {
                    line-height: 1.5rem;
                }
            }
        }

        .socials-container {
            display: flex;
            flex-wrap: wrap;
            gap: 3rem;
            margin-top: 3rem;
        }

        .sub-title:not(:first-of-type) {
            text-transform: uppercase;
            margin-top: 3.5rem;
        }

        .gallery-card {
            margin-bottom: 1.5rem;

            &.-social {
                max-height: 32.4rem;
                height: 32.4rem;

                @include media('<md') {
                    height: unset;
                }

                .wrapper {}

                img {
                    object-fit: unset;
                }
            }

            @include media('<md') {
                padding-right: calc(var(--bs-gutter-x) * .5);
            }

            .wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-avenue-gray;
                aspect-ratio: 1/1;

                >a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;

                    &:hover {
                        .media-download-btn {
                            background-color: $color-avenue-orange;

                            svg {
                                path {
                                    stroke: $color-white
                                }
                            }
                        }
                    }
                }
            }

            .media-download-btn {
                position: absolute;
                width: 4.7rem;
                height: auto;
                aspect-ratio: 1/1;
                background-color: $color-white;
                @include flex-center;
                border-radius: 50%;
                top: 1.2rem;
                right: 1.5rem;
                transition: all .3s ease-out;

                svg {
                    path {
                        transition: all .5s ease-out;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.-portrait {
                .wrapper {
                    aspect-ratio: 65/137;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .floorplans {
        padding-top: 0;
        border-top: 1px solid $color-black;

        >.title-container {
            display: flex;
            align-items: center;
            padding-top: 3.6rem;
            margin-bottom: 5.3rem;

            @include media('<sm') {
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
            }

            @include media('<md') {
                margin-bottom: 0;
            }

            h2 {
                flex: 1;
                font-family: $font-gt-super-ds-md;
                font-weight: 500;
                font-size: 3.5rem;
                line-height: 4.2rem;
                margin-bottom: 0;
                text-transform: uppercase;
            }

        }

        // .title {
        //     display: block;
        //     font-family: $font-gt-super-ds-md;
        //     font-size: 3.5rem;
        //     line-height: 4.2rem;

        //     span {
        //         font-family: $font-termina;
        //         font-weight: 100;
        //         font-size: 3.9rem;
        //         line-height: 4.68rem;
        //         margin-left: 1.2rem;
        //         text-transform: capitalize;
        //     }
        // }

        .section-download-btn {
            @include download-all-btn-avenue();
        }

        .filter-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 1.5rem;
            margin-bottom: 3rem;

            @include media('<sm') {
                margin-top: 3rem;
            }

            .filter-groups-sub-container {
                display: flex;
                flex-wrap: wrap;
                gap: 1.6rem;
            }

            .filter-groups {
                display: flex;
                gap: 1.6rem;

                @include media('<xl') {
                    flex-direction: column;
                    // row-gap: 1.6rem;
                    margin-bottom: 1.5rem;
                }

                @include media('<sm') {
                    // display: none;
                }

                .filter-group {
                    p {
                        font-family: $font-termina;
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        margin-bottom: 1.6rem;
                        text-transform: uppercase;
                    }

                    .filters {
                        display: flex;
                        gap: 1rem;

                        button {
                            background-color: transparent;
                            color: $color-black;
                            padding: .7rem 2.8rem;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            border: 1px solid $color-avenue-orange;
                            transition: .25s ease-in-out;
                            font-family: $font-ff-scala-sans-pro;

                            &.active {
                                background-color: $color-avenue-orange;
                                color: $color-white;
                            }
                        }

                        select {
                            font-family: $font-ff-scala-sans-pro;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            background-color: transparent;
                            cursor: pointer;
                            color: $color-black;
                            padding: .7rem 2.8rem;
                            border: 1px solid $color-avenue-orange;
                            transition: .25s ease-in-out;
                            appearance: none;
                            background-image: var(--chevron-url);
                            background-repeat: no-repeat;
                            background-position: 95% 50%;
                            background-size: 1.75rem;
                            outline: none;
                        }
                    }
                }
            }

            .filter-container.-mobile {
                // display: block;
                display: none;
                width: 100%;
                flex: 1 0 100%;

                label {
                    width: 100%;
                    display: block;
                    margin-bottom: 1.25rem;
                    font-family: $font-termina;
                    font-size: 1.5rem;
                    line-height: 1.8rem
                }

                select {
                    font-family: $font-termina;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    background-color: $color-black;
                    color: $color-white;
                    border: none;
                    outline: none;
                    padding: 1.25rem 2rem;
                    padding-right: 10rem;
                    appearance: none;
                    background-repeat: no-repeat;
                    background-position: 95% 50%;
                    background-size: 1.75rem;
                    margin-bottom: 1.5rem;
                }

                @include media('>sm') {
                    // display: none;
                }
            }
        }

        .floorplans-container {

            .no-plans-found {
                text-align: center;

                h2 {
                    margin: 3rem 0;
                    font-family: $font-termina;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }

                button {
                    margin-inline: auto;
                }
            }

            .floorplan-card {
                margin-bottom: 3rem;

                .wrapper {
                    padding-top: 5rem;
                    padding-bottom: 2.6rem;
                    padding-left: 3.2rem;
                    padding-right: 2.3rem;
                    background-color: $color-avenue-gray;
                    position: relative;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;

                        @include can-hover {
                            .download-indicator {
                                svg {
                                    circle {
                                        fill: $color-avenue-orange;
                                    }

                                    path {
                                        stroke: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                .download-indicator {
                    position: absolute;
                    right: 1.1rem;
                    top: 1.1rem;

                    svg {
                        transition: all .3s ease-out;

                        circle {
                            transition: inherit;
                        }

                        path {
                            transition: inherit;
                        }
                    }
                }

                .details-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    font-family: $font-termina;

                    .specs {
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        text-transform: capitalize;

                        .plan {
                            font-size: 2.5rem;
                            margin-bottom: 1.25rem;
                        }
                    }

                    .price-and-size {
                        font-family: $font-termina;
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        align-self: flex-end;

                        .price {
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                        }
                    }

                }

                .media {
                    text-align: center;

                    img {
                        aspect-ratio: 1/1;
                        max-height: 29.7rem;
                        width: 100%;
                        object-fit: contain;
                        margin-bottom: 1.4rem;
                    }
                }


                .links {
                    display: flex;
                    justify-content: center;
                    gap: .8rem;
                }
            }
        }
    }

    .documents {
        padding-bottom: 6.3rem;

        @include media('<md') {
            padding-bottom: 3rem;
        }

        .title {
            font-family: $font-gt-super-ds-md;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        .table-container {
            padding-top: 0;
            padding-left: 2.5rem;

            @include media('<md') {
                padding-left: 0;
            }
        }

        .accordions-container {
            margin-bottom: 6rem;
        }

        .single-project-accordion {
            .wrapper {
                padding: 1.6rem 2.6rem 2.2rem 0;
            }

            .trigger {
                font-family: $font-termina;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: .1rem;
                text-transform: uppercase;
                font-weight: 500;
                height: unset;
                text-align: left;
            }

            .text {
                font-family: $font-ff-scala-sans-pro;
                padding-top: 1.4rem;
                font-size: 1.5rem;
                line-height: 1.8rem;
                margin-bottom: 2.8rem;
            }

            .buttons-container {
                a {
                    font-family: $font-termina;
                    font-size: 1.15rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    background-color: $color-avenue-orange;
                    color: $color-white;
                    padding: .8rem 2rem;
                    padding-top: 1rem;
                    transition: all .3s ease-out;
                    border: 1px solid transparent;

                    @include can-hover {
                        background-color: transparent;
                        color: $color-black;
                        border: 1px solid $color-avenue-orange;
                    }

                }
            }
        }
    }

    .contact {
        .title {
            font-family: $font-gt-super-ds-md;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        .contacts-container {
            display: flex;
            flex-wrap: wrap;
            gap: 12.2rem;

            @include media('<sm') {
                flex-direction: column;
                gap: 0;
            }

            .contact-card {
                font-family: $font-ff-scala-sans-pro;
                font-size: 1.5rem;
                line-height: 1.8rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                border: none;

                a {
                    transition: all .3s ease-out;

                    @include can-hover {
                        color: $color-avenue-orange;
                    }
                }

                .name {
                    font-family: $font-termina;
                    margin-bottom: 1.25rem;
                }

                .project-btn {
                    margin-top: 1.5rem;
                    font-size: 1rem;
                    line-height: 1.2rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    .single-project-page-footer {
        padding: 2.5rem 0;
        background-color: $color-black;
        color: $color-white;
        font-family: $font-ff-scala-sans-pro;
        font-size: 1.6rem;

        @include media('<md') {
            padding-bottom: 8rem;
        }

        .top-row {
            display: flex;
            justify-content: space-between;

            .logo {
                width: 100%;
                height: fit-content;
                display: block;
                max-width: 18rem;
                height: 7rem;


                @include media('<xl') {
                    margin-bottom: 3rem;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        transition: all .3s ease-out;
                    }
                }

                @include can-hover {
                    svg {
                        path {
                            fill: $color-avenue-orange;
                        }
                    }
                }
            }

            .links-container {
                display: flex;
                justify-content: flex-end;
                gap: 8rem;
                height: 100%;

                @include media('<xl') {
                    justify-content: flex-end;
                    gap: 4rem;
                }

                @include media('<lg') {
                    justify-content: space-between;
                }

                @include media('<sm') {
                    padding-right: unset;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2rem;
                }

                .link-group {
                    flex: 0 1 content;

                    a {
                        display: block;
                        transition: all .3s ease-out;
                        cursor: pointer;
                        white-space: nowrap;
                        line-height: 2.2rem;
                        margin-bottom: .4rem;

                        @include can-hover {
                            cursor: pointer;
                            color: $color-avenue-orange;
                        }
                    }

                    .address {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                    }

                    .nested-link {
                        padding-left: 1.5rem;
                    }

                    .location-container {
                        margin-bottom: 2.8rem;

                        a {
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                        }

                        &:nth-child(2) {
                            margin-bottom: 4.2rem;
                        }

                        >.title {
                            margin-bottom: unset;
                            font-weight: 700;
                        }
                    }
                }

            }

            .map-container {

                @include media('<xl') {
                    justify-content: flex-end;
                }

                @include media('<md') {
                    justify-content: flex-start;
                }

                svg {
                    height: 100%;
                    width: 100%;
                    max-height: 26.68rem;

                    @include media('<xl') {
                        max-height: unset;
                    }

                    @include media('<lg') {
                        max-height: 40rem;
                    }

                    @include media('<sm') {
                        max-height: unset;
                        width: 100%;
                    }
                }
            }

        }

        .bottom-row {
            padding-top: 2rem;
            padding-bottom: 2rem;

            .disclaimer {
                font-family: $font-ff-scala-sans-pro;
                font-size: 1rem;
                line-height: 1.2rem;
                max-width: 111rem;
            }

            .logos-and-social {
                display: flex;
                justify-content: space-between;
                padding-top: 2.7rem;

                @include media('<md') {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2rem;
                }

                .logos {
                    display: flex;
                    gap: 2.1rem;

                    a {
                        svg {
                            path {
                                transition: all .3s ease-out;
                            }
                        }

                        @include can-hover {
                            svg {
                                path {
                                    fill: $color-avenue-orange;
                                }
                            }
                        }
                    }
                }

                .socials {
                    display: flex;
                    gap: 1.5rem;

                    a {
                        svg {
                            path {
                                transition: all .3s ease-out;
                            }
                        }

                        @include can-hover {
                            svg {
                                path {
                                    fill: $color-avenue-orange;
                                }
                            }
                        }
                    }

                    a.facebook {
                        @include can-hover {
                            .background {
                                fill: $color-avenue-orange !important;
                            }

                            path {
                                fill: transparent !important;
                            }
                        }
                    }
                }
            }
        }

        .policy {
            border-top: 1px solid $color-white;
            padding-top: 1.2rem;
            font-family: $font-ff-scala-sans-pro;
            font-size: 1.2rem;
            line-height: 1.44rem;

            a {
                transition: all .3s ease-out;

                @include can-hover {
                    color: $color-avenue-orange;
                }
            }
        }
    }

}