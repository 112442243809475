.account-hub {
    background-color: $color-light-blue;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;

    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4rem;

        .text {
            @include body-20-medium;
        }
    }

    >.wrapper {
        display: flex;
        padding-top: 5.5rem;
        flex: 1 1 content;
        max-height: calc(100dvh - 6.5rem);

        .container-left {
            padding-top: 3.7rem;
            // padding-top: 0;
            border-right: 1px solid #D6D5C4;
            width: 45.3rem;
            overflow-y: auto;


            .title-container {
                padding-left: 2.2rem;
                margin-bottom: 7.1rem;
                display: flex;
                align-items: center;
                gap: 1.6rem;

                img {
                    width: 6.4rem;
                    height: auto;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                }

                .text-group {
                    font-weight: 500;

                    h1 {
                        @include title-35;
                    }
                }
            }

            .options-container {
                .option {
                    margin-bottom: 1.2rem;

                    a {
                        width: 100%;
                        padding: 1.2rem 2.6rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: 600;

                        svg {
                            margin-right: 1rem;
                        }

                        span {
                            display: inline-block;
                            min-width: 3rem;
                            width: auto;
                            height: auto;
                            padding: .3rem .6rem;
                            border-radius: 50%;
                            text-align: center;
                            aspect-ratio: 1/1;
                            background-color: $color-red;
                            color: $color-white;
                        }

                        &.-active {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }

        .container-right {
            flex: 1;
            display: flex;
            max-height: inherit;

            .not-found-container {
                width: 100%;
                padding-top: 3.7rem;

                h2 {
                    text-align: center;
                    font-size: 2.25rem;
                }
            }

            .top-options-container {
                padding-top: 3.7rem;
                padding-left: 3.7rem;
            }
        }
    }

    .account-hub-footer {
        background-color: $color-black;
        color: $color-white;
        flex: 0 1 content;
        max-height: 6.5rem;

        >.wrapper {
            padding-top: 1.75rem;
            padding-bottom: 1.75rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media('<md') {
                padding-bottom: 11rem;
            }
        }

        .content-left {
            display: flex;
            align-items: center;
            gap: 1.4rem;

            .text {
                @include body-15;
            }
        }

        .content-right {
            .text {
                @include body-15;
            }
        }

    }
}