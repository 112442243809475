@import '../../utility/project/mayfair-west-variables';

.main-nav.-mayfairwest {
    >.wrapper {}

    .logo {
        a {}

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .links-container {

        button {
            font-family: $font-romie;
            font-weight: 500;
            font-size: 1.5rem;
            margin-right: 5.2rem;

            &.-btn {

                &:hover {}

                &::after {}
            }

            &::after {
                height: 1px;
            }

            &.-active {
                &::after {}
            }

            &:hover {
                &::after {}
            }
        }

        a:last-of-type {}
    }

    .links-container-mobile {

        @include media('<=md') {}

        .logout-mobile {}
    }

    .buttons-group {

        button {}
    }

    .lang-btn {}

    .notification-btn {}

}