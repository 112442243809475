.embla.-login {
    position: relative;

    .embla__dots {
        position: absolute;
        top: 1.6rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 100%;
        gap: 2.3rem;
        z-index: 5;
        padding-inline: 1.7rem;

        .embla__dot {
            position: relative;
            flex: 1;
            background-color: $color-grey;
            height: .5rem;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                background-color: $color-white;
            }

            &.-viewed {
                &::after {
                    width: 100%;
                }
            }

            &.-selected {
                &::after {
                    // have access to progress because it is a child of the parent that has the variable
                    width: var(--progress);
                }
            }
        }
    }

}