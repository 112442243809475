@mixin title-12 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-15 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-20 {
    font-size: 2rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-25 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-30 {
    font-size: 3rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-35 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-poppins;
}

@mixin title-55 {
    font-size: 5.5rem;
    font-weight: 500;
    line-height: normal;
    font-family: $font-roboto;
}