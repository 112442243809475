.pdf-viewer {

    @include media('<lg') {
        margin-bottom: 3.5rem;
    }

    .media {
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        margin-bottom: 3.1rem;
        cursor: pointer;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .content-group {
        .title {
            margin-bottom: .8rem;
            @include body-15-semb;
        }

        .content {
            margin-bottom: 1.3rem;
            min-height: 4.6rem;
        }
    }

    .btns-container {
        display: flex;
        gap: 1.2rem;
    }
}

.pdf-viewer-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.5);
        cursor: pointer;
    }

    .modal {
        display: block;
        position: absolute;
        left: 50%;
        top: 53%;
        transform: translate(-50%, -50%);
        width: 90%;
        // aspect-ratio: 1/1.414; // A4 aspect ratio
        // background-color: $color-white;
        padding: 0;
        z-index: 11;
        margin: 0;

        @include media('<md') {
            top: 47%;
        }

        &.-landscape {
            // width: auto;
            // aspect-ratio: 4/3;
        }

        .document {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .react-pdf__Page {
                min-width: unset !important;
                min-height: unset !important;
                aspect-ratio: inherit;
                height: 100%;
            }

            .react-pdf__Page__textContent {
                width: 100% !important;
                height: 100% !important;
            }

            .react-pdf__Page__annotations {
                display: none;
            }

            .react-pdf__Page__canvas {
                margin: 0 auto;
                width: 100% !important;
                height: 100% !important;
                max-height: 95dvh;
            }
        }
    }

    .page-controller {
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translateX(-50%);
        display: flex;
        background-color: $color-white;
        box-shadow: 0 0 1.5rem 0 rgb(0 0 0 / 10%);
        padding: .5rem 1rem;
        z-index: 15;

        align-items: center;
        gap: 1.25rem;

        button {
            @include flex-center;
        }
    }
}