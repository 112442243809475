@import '../../../utility/project/olia-variables';

@mixin download-all-btn-olia {
    left: unset;
    top: unset;
    right: unset;
    position: relative;
    background-color: $color-olia-blue;
    color: $color-black;
    width: fit-content;
    gap: 1.1rem;
    padding: .8rem 1.6rem;
    padding-bottom: .48rem;
    padding-top: 1rem;
    font-family: $font-olia-neue-haas-grotesk;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.2rem;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    border-radius: 10rem;
    text-transform: uppercase;

    svg {
        width: 1.5rem;
        height: auto;
        aspect-ratio: 1/1;

        path {
            stroke: $color-black;
            transition: all .5s ease-out;
        }
    }

    @include can-hover {
        border: 1px solid $color-olia-blue;
        background-color: transparent;
        color: $color-black;

        svg {
            path {
                stroke: $color-olia-blue;
            }
        }
    }

}

@mixin download-btn-olia {
    background-color: $color-olia-red;
    border-radius: 10rem;
    padding: .5rem 1.2rem;
    font-family: $font-olia-neue-haas-grotesk;
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-white;
    border: 1px solid transparent;
    transition: all .3s ease-out;
    text-transform: uppercase;

    @include can-hover {
        border: 1px solid $color-olia-red;
        background-color: transparent;
        color: $color-black;
    }
}

@mixin share-btn-olia {
    background-color: transparent;
    border-radius: 10rem;
    padding: .5rem 1.2rem;
    font-family: $font-olia-neue-haas-grotesk;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: 500;
    color: $color-black;
    border: 1px solid $color-black;
    transition: all .3s ease-out;
    text-transform: uppercase;
    min-width: 9.8rem;

    @include can-hover {
        background-color: $color-black;
        color: $color-white;
    }

}

.project-page.olia {

    &.nav-padding {
        @include media('<md') {
            padding-top: 5.5rem;
            padding-bottom: 0;
        }
    }

    .project-btn {
        background-color: $color-olia-red;
        color: $color-white;
        outline: none;
        border: none;
        font-family: $font-olia-neue-haas-grotesk;
        font-size: 1.15rem;
        text-align: center;
        width: fit-content;
        font-weight: 500;
        padding: .8rem 4rem;
        padding-top: 1rem;
        text-transform: uppercase;
        border: 1px solid transparent;
        border-radius: 10rem;

        &.-small {
            padding: .8rem 1.5rem;
            padding-top: 1rem;
        }

        @include can-hover {
            background-color: transparent;
            color: $color-black;
            border: 1px solid $color-olia-red;
        }
    }

    &.nav-padding {
        padding-top: 7rem;
    }

    h2 {}

    h3 {}

    .validation-container {
        width: 100dvw;
        height: 100dvh;
        @include flex-center;

        h1 {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 5rem;

            @include media('<md') {
                font-size: 3rem;
            }
        }
    }


    .sub-title {}

    section {
        background-color: $color-light-gray;
        border-bottom: 0;

        >.title {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 3.5rem;
            font-weight: 100;
            line-height: 4.2rem;
            padding-top: 5.5rem;
            padding-bottom: 3.5rem;
        }
    }

    section:last-of-type {}

    .section-download-btn {}

    .placeholder-card {}

    .landing {
        // background-color: $color-white;
        color: $color-black;

        .-desktop {

            .content-left {
                padding-top: 7.8rem;

                .title {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 3.5rem;
                    line-height: 4.2rem;
                    text-transform: uppercase;
                    white-space: pre-line;
                }

                .wrapper {}

                .greeting {
                    align-items: flex-start;

                    .text-group {
                        h2 {
                            font-family: $font-olia-neue-haas-grotesk;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 3.5rem;
                            line-height: 4.2rem;
                            margin-bottom: 1rem;
                        }

                        p {
                            font-family: $font-olia-neue-haas-grotesk;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            font-weight: 300;
                            margin-bottom: 4.3rem;

                            .project-name {
                                text-transform: uppercase;
                            }

                            .project-developer {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .presentation-centre {

                    a {}
                }

                .project-blurb {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.5rem;
                    line-height: 2.625rem;
                    font-weight: 400;
                }
            }

            .content-right {

                .media {}
            }
        }

        .-mobile {


            .content-top {
                padding-bottom: 3.5rem;

                .title {
                    padding-inline: 2rem;
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 3.5rem;
                    line-height: 4.2rem;
                    text-transform: uppercase;
                    white-space: pre-line;
                }

                .greeting {
                    align-items: center;

                    img {
                        border-radius: 10rem;
                    }

                    .text-group {
                        h2 {
                            font-family: $font-olia-neue-haas-grotesk;
                            font-size: 3rem;
                            text-transform: uppercase;
                            font-weight: 600;
                            line-height: 4.2rem;
                            margin-bottom: 0;
                        }

                        p {
                            font-family: $font-olia-neue-haas-grotesk;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            font-weight: 300;
                            margin-bottom: 0;
                            max-width: 40rem;
                        }
                    }
                }
            }

            .content-bottom {

                .presentation-centre {}

                .project-blurb {}

                .project-download-all-btn {}
            }
        }
    }

    // ANCHOR BRAND
    .brand {
        font-family: $font-olia-neue-haas-grotesk;
        background-color: $color-white;
        line-height: 2.5rem;

        .title-container {
            >.title {
                display: flex;
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 3.5rem;
                line-height: normal;
                justify-content: space-between;
                align-items: center;
                font-weight: 600;
            }
        }

        .section-download-btn {
            @include download-all-btn-olia();
        }

        .blurb {
            max-width: 84.1rem;
            margin-bottom: 5.5rem;
        }

        .brand-card {
            .title {
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 1.4rem;
            }

            .content {
                max-width: 24.5rem;
                margin-bottom: 3rem;
                line-height: normal;
            }

            .links {
                display: flex;
                gap: .85rem;

                .generic-btn.-black {
                    @include download-btn-olia();
                }

                .generic-btn.-border-black {
                    @include share-btn-olia();
                }
            }
        }

    }

    // Anchor Overview
    .overview {
        padding-top: 4.4rem;
        padding-bottom: 9.3rem;
        border-bottom: 1px solid $color-black;

        @include media('<md') {
            padding-top: 0;
        }

        .title-container {
            margin-bottom: 5.8rem;

            .wrapper {
                border-bottom: 1px solid $color-black;
            }

            .title {
                width: 100%;
                font-weight: 600;
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 3.5rem;
                text-transform: uppercase;
                margin-bottom: 0 !important;
                padding-bottom: 3.5rem;
            }

        }

        .overview-container {
            row-gap: 3rem;

            .overview-card {
                padding-inline: 2.2rem;

                .title {
                    font-weight: 600;
                    letter-spacing: .1em;
                }

                .content {
                    font-size: 1.2rem;
                }
            }
        }

        .overview-details-container {
            border-bottom: 1px solid $color-black;
            padding-bottom: 8.8rem;

            @include media('<md') {
                padding-bottom: 0;
            }

            .overview-detail {
                @include media('<sm') {
                    margin-bottom: 4rem;
                }

                .title {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-weight: 400;
                    line-height: 1.8rem;
                    font-size: 1.5rem;
                    letter-spacing: .1rem;
                }

                .description {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }
            }
        }

        .overview-brand-story {
            padding-top: 3.7rem;

            >.wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-bottom: 5.6rem;

                @include media('<md') {
                    flex-direction: column;
                    padding-bottom: 0;
                }
            }

            h2 {
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 3.5rem;
                font-weight: 500;
                line-height: 4.2rem;
                flex: 1;
            }

            p {
                flex: 1;
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .overview-media-container {}
    }

    // Anchor Offering
    .offering {
        .title-container {
            >.title {
                font-family: $font-olia-neue-haas-grotesk;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 3.5rem;
                // margin-bottom: 0 !important;
                // padding-bottom: 3.5rem;
            }
        }

        .table-container {
            padding-top: unset;
        }
    }

    // ANCHOR Media & Social
    .media,
    .social {
        padding-bottom: 0;

        .title {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 3.5rem;
            line-height: 4.2rem;
            font-weight: 600;
        }

        // any title that isn't the first one

        .sub-title {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 1.5rem;
            line-height: 2.4rem;
            letter-spacing: .2rem;
            font-weight: 400;
            margin-bottom: 1.5rem;
        }

        .description {
            p {
                white-space: pre-line;
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 1.5rem;
                line-height: 2.5rem;
                max-width: 84.1rem;
                margin-bottom: 7.4rem;
            }
        }

        .socials-container {
            margin-bottom: 9.3rem;

            .sub-title {
                font-size: 2rem;
                letter-spacing: .1em;
            }

            .wrapper-container {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 3rem;
                margin-top: 1.5rem;

                @include media('<lg') {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media('<md') {
                    grid-template-columns: repeat(1, 1fr);
                }

                .gallery-card {
                    &.-social {
                        height: 100%;
                        max-height: unset;

                        .wrapper {
                            aspect-ratio: unset;
                        }

                        img {
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .sub-title:not(:first-of-type) {
            text-transform: uppercase;
            margin-top: 3.5rem;
        }

        .gallery-card {
            margin-bottom: 1.5rem;

            &.-social {
                max-height: 32.4rem;
                height: 32.4rem;

                @include media('<md') {
                    height: unset;
                }

                .wrapper {}

                img {
                    object-fit: unset;
                }
            }

            @include media('<md') {
                padding-right: calc(var(--bs-gutter-x) * .5);
            }

            .wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-avenue-gray;
                aspect-ratio: 1/1;

                >a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;

                    &:hover {
                        .media-download-btn {
                            background-color: $color-olia-blue;

                            svg {
                                path {
                                    stroke: $color-white
                                }
                            }
                        }
                    }
                }
            }

            .media-download-btn {
                position: absolute;
                width: 4.7rem;
                height: auto;
                aspect-ratio: 1/1;
                background-color: $color-white;
                @include flex-center;
                border-radius: 50%;
                top: 1.2rem;
                right: 1.5rem;
                transition: all .3s ease-out;

                svg {
                    path {
                        transition: all .5s ease-out;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.-portrait {
                .wrapper {
                    aspect-ratio: 65/137;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .floorplans {
        padding-top: 0;
        border-top: 1px solid $color-black;

        >.title-container {
            display: flex;
            align-items: center;
            padding-top: 3.6rem;
            margin-bottom: 5.3rem;

            @include media('<sm') {
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
            }

            @include media('<md') {
                margin-bottom: 0;
            }

            h2 {
                flex: 1;
                font-family: $font-olia-neue-haas-grotesk;
                font-weight: 600;
                font-size: 3.5rem;
                line-height: normal;
                margin-bottom: 0;
                text-transform: uppercase;
            }

        }

        // .title {
        //     display: block;
        //     font-family: $font-olia-neue-haas-grotesk;
        //     font-size: 3.5rem;
        //     line-height: 4.2rem;

        //     span {
        //         font-family: $font-olia-neue-haas-grotesk;
        //         font-weight: 100;
        //         font-size: 3.9rem;
        //         line-height: 4.68rem;
        //         margin-left: 1.2rem;
        //         text-transform: capitalize;
        //     }
        // }

        .section-download-btn {
            @include download-all-btn-olia();
        }

        .filter-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 1.5rem;
            margin-bottom: 3rem;

            @include media('<sm') {
                margin-top: 3rem;
            }

            .filter-groups-sub-container {
                display: flex;
                flex-wrap: wrap;
                gap: 1.6rem;
            }

            .filter-groups {
                display: flex;
                gap: 1.6rem;

                @include media('<xl') {
                    flex-direction: column;
                    // row-gap: 1.6rem;
                    margin-bottom: 1.5rem;
                }

                @include media('<sm') {
                    // display: none;
                }

                .filter-group {
                    p {
                        font-family: $font-olia-neue-haas-grotesk;
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        margin-bottom: 1.6rem;
                    }

                    .filters {
                        display: flex;
                        gap: 1rem;

                        button {
                            background-color: transparent;
                            color: $color-black;
                            padding: .7rem 2.8rem;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            background: $color-white;
                            transition: .25s ease-in-out;
                            font-family: $font-olia-neue-haas-grotesk;
                            border-radius: 10rem;

                            &.active {
                                background-color: $color-black;
                                color: $color-white;
                            }
                        }

                        select {
                            font-family: $font-olia-neue-haas-grotesk;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            background-color: $color-white;
                            cursor: pointer;
                            color: $color-black;
                            padding: .7rem 2.8rem;
                            border-radius: 10rem;
                            transition: .25s ease-in-out;
                            appearance: none;
                            background-image: var(--chevron-url);
                            background-repeat: no-repeat;
                            background-position: 95% 50%;
                            background-size: 1.75rem;
                            border: none;
                            outline: none;
                        }
                    }
                }
            }

            .filter-container.-mobile {
                // display: block;
                display: none;
                width: 100%;
                flex: 1 0 100%;

                label {
                    width: 100%;
                    display: block;
                    margin-bottom: 1.25rem;
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.5rem;
                    line-height: 1.8rem
                }

                select {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    background-color: $color-black;
                    color: $color-white;
                    border: none;
                    outline: none;
                    padding: 1.25rem 2rem;
                    padding-right: 10rem;
                    appearance: none;
                    background-repeat: no-repeat;
                    background-position: 95% 50%;
                    background-size: 1.75rem;
                    margin-bottom: 1.5rem;
                }

                @include media('>sm') {
                    // display: none;
                }
            }
        }

        .floorplans-container {

            .no-plans-found {
                text-align: center;

                h2 {
                    margin: 3rem 0;
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }

                button {
                    margin-inline: auto;
                }
            }

            .floorplan-card {
                margin-bottom: 3rem;

                .wrapper {
                    padding-top: 5rem;
                    padding-bottom: 2.6rem;
                    padding-left: 3.2rem;
                    padding-right: 2.3rem;
                    background-color: $color-light-blue;
                    position: relative;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;

                        @include can-hover {
                            .download-indicator {
                                svg {
                                    circle {
                                        fill: $color-olia-blue;
                                    }

                                    path {
                                        stroke: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                .download-indicator {
                    position: absolute;
                    right: 1.1rem;
                    top: 1.1rem;

                    svg {
                        transition: all .3s ease-out;

                        circle {
                            transition: inherit;
                        }

                        path {
                            transition: inherit;
                        }
                    }
                }

                .details-container {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    font-family: $font-olia-neue-haas-grotesk;

                    .specs {
                        display: flex;
                        gap: 1rem;
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        text-transform: capitalize;

                        .plan {
                            padding-top: 1rem;
                            font-size: 3rem;
                        }
                    }

                    .generic-btn {
                        @include share-btn-olia();
                    }
                }

                .media {
                    text-align: center;

                    img {
                        aspect-ratio: 1/1;
                        max-height: 29.7rem;
                        width: 100%;
                        object-fit: contain;
                        margin-bottom: 1.4rem;
                    }
                }


                .links {
                    display: flex;
                    justify-content: center;
                    gap: .8rem;
                }
            }
        }
    }

    .documents {
        padding-bottom: 6.3rem;

        @include media('<md') {
            padding-bottom: 3rem;
        }

        .title {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        .table-container {
            padding-top: 0;
            padding-left: 2.5rem;

            @include media('<md') {
                padding-left: 0;
            }
        }

        .accordions-container {
            margin-bottom: 6rem;
        }

        .single-project-accordion {
            .wrapper {
                padding: 1.6rem 2.6rem 2.2rem 0;
            }

            .trigger {
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: .1rem;
                text-transform: uppercase;
                font-weight: 500;
                height: unset;
                text-align: left;
            }

            .text {
                font-family: $font-olia-neue-haas-grotesk;
                padding-top: 1.4rem;
                font-size: 1.5rem;
                line-height: 1.8rem;
                margin-bottom: 2.8rem;
            }

            .buttons-container {
                a {
                    font-family: $font-olia-neue-haas-grotesk;
                    font-size: 1.15rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    background-color: $color-olia-blue;
                    color: $color-white;
                    padding: .8rem 2rem;
                    padding-top: 1rem;
                    transition: all .3s ease-out;
                    border: 1px solid transparent;

                    @include can-hover {
                        background-color: transparent;
                        color: $color-black;
                        border: 1px solid $color-olia-blue;
                    }

                }
            }
        }
    }

    .contact {
        .title {
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 4.2rem;
        }

        .contacts-container {
            display: flex;
            flex-wrap: wrap;
            gap: 12.2rem;

            @include media('<sm') {
                flex-direction: column;
                gap: 0;
            }

            .contact-card {
                font-family: $font-olia-neue-haas-grotesk;
                font-size: 1.5rem;
                line-height: 1.8rem;
                display: flex;
                align-items: center;
                gap: 5rem;
                text-align: left;
                border: none;

                a {
                    transition: all .3s ease-out;
                    display: block;

                    @include can-hover {
                        color: $color-olia-red;
                    }
                }

                .name {
                    font-family: $font-olia-neue-haas-grotesk;
                    margin-bottom: 1.25rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                .contact-actions {
                    display: flex;
                    gap: 1.5rem;
                    margin-top: 3rem;
                }

                .download-btn {
                    @include download-btn-olia();
                }

                .share-btn {
                    @include share-btn-olia();
                }
            }
        }
    }

    .single-project-page-footer {
        padding: 4.5rem 0;
        color: $color-white;
        font-family: $font-olia-neue-haas-grotesk;
        background: linear-gradient(180deg, #D70B02 10.5%, #F7BB35 28%, #95C4E0 64%);
        font-size: 1.6rem;

        @include media('<md') {
            padding-bottom: 8rem;
        }

        .footer-logo {
            margin-bottom: 4rem;
        }

        .wrapper {
            display: block;
            padding-top: unset;
            padding-bottom: unset;
        }

        .contact-details-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            margin-bottom: 4rem;

            .contact-details {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                a {
                    display: block;
                }
            }

            .back-to-top-btn {
                background: transparent;
                display: flex;
                gap: 1rem;

                svg {
                    transition: all .3s ease-out;
                }

                @include can-hover {
                    svg {
                        transform: translateY(-.4rem);
                    }
                }
            }
        }

        .developer-logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            padding-top: 2.1rem;
            padding-bottom: 1.4rem;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
        }

        .policy-container {
            padding-top: 1.2rem;
            font-family: $font-olia-neue-haas-grotesk;
            font-size: 1.4rem;
            line-height: 1.44rem;
            display: flex;
            gap: 9.5rem;

            .policy-links {
                display: flex;
                gap: 2.44rem;
            }

            a {
                transition: all .3s ease-out;
                text-decoration: underline;
            }
        }
    }

}