.single-project-page-footer {
    background-color: $color-black;
    color: $color-white;

    >.wrapper {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media('<md') {
            padding-bottom: 11rem;
        }
    }

    .content-left {
        display: flex;
        align-items: center;
        gap: 1.4rem;

        .text {
            @include body-15;
        }
    }

    .content-right {
        .text {
            @include body-15;
        }
    }
}