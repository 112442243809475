@mixin body-10 {
    font-weight: 400;
    font-size: 1rem;
    font-family: $font-poppins;
}

@mixin body-12 {
    font-weight: 400;
    font-size: 1.2rem;
    font-family: $font-poppins;
}

@mixin body-12-medium {
    font-weight: 500;
    font-size: 1.2rem;
    font-family: $font-poppins;
}

@mixin body-13 {
    font-weight: 400;
    font-size: 1.3rem;
    font-family: $font-poppins;

}

@mixin body-15 {
    font-weight: 400;
    font-size: 1.5rem;
    font-family: $font-poppins;
}

@mixin body-15-medium {
    font-weight: 500;
    font-size: 1.5rem;
    font-family: $font-poppins;
}

@mixin body-15-semb {
    font-weight: 600;
    font-size: 1.5rem;
    font-family: $font-poppins;
}

@mixin body-20-medium {
    font-weight: 500;
    font-size: 2rem;
    font-family: $font-poppins;
}