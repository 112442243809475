%btn-extendable {
    padding: .05rem 1.35rem;
    transition: all .3s ease-out;
}

.project-download-all-btn {
    padding: .6rem 2.6rem;
    background-color: $color-aquamarine;
    color: $color-black;
    @include label-15;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    transition: all .25s ease-in-out;
    width: fit-content;
    cursor: pointer;

    @include can-hover {
        opacity: .8;
    }

    &.-ghost {
        background: transparent;

        &.-white {
            border: 1px solid $color-white;
            color: $color-white;
            transition: all .25s ease-in-out;

            svg {
                path {
                    stroke: $color-white;
                    transition: all .25s ease-in-out;
                }
            }

            @include can-hover {
                background: $color-white;
                color: $color-black;
                opacity: 1;

                svg {
                    path {
                        stroke: $color-black;
                    }
                }
            }
        }
    }
}

.project-btn {
    padding: .6rem 2.6rem;
    background-color: $color-white;
    color: $color-black;
    @include label-15;
    display: flex;
    gap: 1.6rem;
    transition: all .3s ease-in-out;
    border: 1px solid $color-black;

    &.-rounded {
        border-radius: 10rem;
    }

    &.-dark {
        background-color: $color-black;
        color: $color-white;

        &:hover {
            background-color: transparent;
            border: 1px solid $color-black;
            color: $color-black
        }
    }

}

.generic-btn {
    @extend %btn-extendable;

    &.-black {
        background-color: $color-black;
        color: $color-white;
        border: 1px solid transparent;

        &:hover {
            background-color: transparent;
            border: 1px solid $color-black;
            color: $color-black;
        }
    }

    &.-white {
        background-color: $color-white;
        color: $color-black;

        &:hover {
            background-color: $color-black;
            color: $color-white;
        }
    }

    &.-border-black {
        background-color: transparent;
        border: 1px solid $color-black;
        color: $color-black;

        &:hover {
            background-color: $color-black;
            color: $color-white;
        }
    }
}

.big-btn {
    padding: 1.2rem 3.55rem;
    width: 100%;
    @include body-15-semb;
    font-family: $font-roboto;

    &.-black {
        background-color: $color-black;
        color: $color-white;
        border: 1px solid transparent;
        transition: all .3s ease-out;

        &:hover {
            background-color: transparent;
            border: 1px solid $color-black;
            color: $color-black;
        }
    }
}

.logo-btn {
    display: flex;
    align-items: center;
    gap: 3.3rem;
    width: 100%;
    height: 100%;
    padding: 1.6rem 2.1rem;
    border: 1px solid $color-black;
    @include body-15;
    margin-bottom: 1.4rem;
    max-height: 4.8rem;
    transition: all .3s ease-out;

    svg {
        transition: all .3s ease-out;

        path {
            transition: inherit;
        }
    }

    &:hover {
        background-color: $color-black;
        color: $color-white;
    }

    &.-blacksvg {
        &:hover {
            svg {
                fill: $color-white;

                path {
                    fill: inherit;
                }
            }
        }
    }

    .logo {
        width: 2.3rem;
        height: auto;
        aspect-ratio: 1/1;
        overflow: visible;
        display: flex;
        align-items: center;

        svg {
            width: 100%;
            height: 100%;
            // width: 2.3rem;
            // height: 1.3rem;
        }
    }

    &.-rounded {
        border-radius: 10rem;
    }

}