.park-residences-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        @include flex-center;
        @include title-30;
    }
}

#park-residences-canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}