@import '../../../utility/project/avenue-variables';

.project-login.-avenue {

    p {
        font-family: $font-termina;
    }

    .login-container {
        background-color: $color-avenue-dark-brown;
        padding-top: 6.5rem;
        padding-bottom: 0;
        overflow: hidden;
        position: relative;

        .bg-logo {
            position: absolute;
            bottom: 0;
            left: 41.5%;
            width: 100%;
            height: 100%;
            max-height: 79.4rem;
            object-fit: cover;
            z-index: 0;
            pointer-events: none;
            overflow: visible;
        }

        .content-container {
            position: relative;
            z-index: 1;
            justify-content: flex-start;

            .project-group {
                .logo {
                    width: 100%;
                    max-height: 12rem;
                    height: auto;
                }
            }
        }

    }

    .inline-input {

        input {
            font-family: $font-termina;
            font-weight: 500;

            &::placeholder {
                opacity: 1;
                color: $color-black;
            }
        }

        p {
            font-family: $font-termina;
            font-weight: 300;
        }
    }


    .big-btn {
        background-color: $color-avenue-orange;
        color: $color-white;
        font-family: $font-termina;
        font-weight: 500;
    }

    .separator {
        color: $color-white;
        font-family: $font-termina;

        span {
            background-color: $color-white;
        }
    }

    .logo-btn {
        font-weight: 500;
        font-family: $font-termina;
        background-color: $color-white;
        border-radius: 0;
        font-size: 1.2rem;
        letter-spacing: .075rem;
        border: 2px solid transparent;

        @include can-hover {
            color: $color-black;
            border: 2px solid $color-avenue-orange;
        }
    }

    .slider-container {
        flex: 1 0 60%;
        position: relative;

        @include media('<lg') {
            flex-basis: 40%;
        }

        @include media('<sm') {
            flex-basis: 25%;
        }

        img,
        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}