.single-project-nav-mobile.-avenue {
    background-color: $color-avenue-dark-brown;
    color: $color-white;
    padding: 1.5rem 0;
    font-family: $font-ff-scala-sans-pro;

    @include media('>md') {
        display: none;
    }

    .wrapper {
        padding-inline: 1.9rem;
        justify-content: flex-end;
    }

    .menu-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .hamburger {
            height: .6rem;
            width: 3rem;
            margin-bottom: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            span {
                position: absolute;
                display: block;
                height: 2px;
                left: 0;
                top: 50%;
                width: 3rem;
                background-color: $color-avenue-orange;
                transition: all .3s ease-in-out;

                &:first-of-type {
                    transform: translateY(-.3rem);
                }

                &:last-of-type {
                    transform: translateY(.3rem);
                }
            }

            &.-open {
                span {
                    &:first-of-type {
                        transform: rotate(45deg);
                    }

                    &:last-of-type {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    .link {
        font-size: 1rem;

        span {
            opacity: .6;
            transition: all .3s ease-in-out;
        }

        svg {
            opacity: .4;
            transition: all .3s ease-in-out;
        }

        .logo {
            width: 2.25rem;
        }

        &.-active {}
    }
}

.mobile-menu-container {
    @include media('<md') {
        display: block;
    }

    display: none;
    position: fixed;
    bottom: 5.5rem;
    right: 0;
    background-color: $color-white;
    z-index: 50;
    width: 11.6rem;
    height: auto;
    font-family: $font-ff-scala-sans-pro;

    .links-container {
        display: flex;
        flex-direction: column;
        padding: 1rem .8rem;

        .link {
            color: $color-black;
            margin-bottom: 1rem;
            text-transform: capitalize;
        }
    }
}