.AppHub {
    background-color: $color-light-blue;
    height: 100dvh;

    .wrapper {
        display: flex;
        height: 100%;

        .container-left {
            padding-top: 3.7rem;
            border-right: 1px solid #D6D5C4;
            width: 45.3rem;
            overflow-y: auto;

            .user-group {
                display: flex;
                align-items: center;
                padding-left: 2.2rem;
                margin-bottom: 7rem;
                gap: 1.6rem;

                img {
                    width: 6.4rem;
                    height: auto;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    border-radius: 50%;
                }

                .text {
                    h2 {
                        @include title-35;
                    }

                    p {
                        @include body-15;
                    }
                }
            }

            .options-group {
                display: flex;
                flex-direction: column;

                .option {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1.2rem 2.6rem;
                    @include title-15;

                    .label-group {
                        display: flex;
                        gap: 1rem;

                        .text {
                            span {
                                @include title-12;
                            }
                        }
                    }

                    .indicator {
                        @include flex-center;
                        width: 3rem;
                        height: auto;
                        aspect-ratio: 1/1;
                        background-color: $color-red;
                        border-radius: 50%;

                        span {
                            color: $color-white;
                            @include body-12;
                            transform: translateY(.1rem);
                        }
                    }

                    &.-active {
                        background-color: $color-white;
                    }

                    &.-disabled {
                        opacity: .5;
                        pointer-events: none;
                    }
                }
            }
        }

        .container-right {
            padding-top: 3.1rem;
            padding-bottom: 3.1rem;
            padding-left: 4.4rem;
            padding-right: 2.2rem;
            flex: 1;
            overflow-y: auto;

            .projects-container {

                .filters-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 2.7rem;

                    .btns-group {
                        display: flex;
                        gap: 1rem;

                        .filter-btn {
                            background-color: $color-black;
                            padding: .4rem 2.5rem;
                            color: $color-white;
                        }

                        .filter-select {
                            border: 1px solid $color-grey;
                            color: $color-black;
                            padding-inline: 2.5rem;
                        }
                    }

                    .search-input {
                        position: relative;
                        width: 34.1rem;
                        height: 3.2rem;

                        input {
                            width: 100%;
                            height: 100%;
                            padding-inline: 1.4rem;
                            outline: none;
                            border: 1px solid transparent;
                            transition: all .3s ease-in-out;
                            @include title-12;

                            &::placeholder {
                                color: $color-black;
                                opacity: 1;
                            }

                            &:focus {
                                border: 1px solid $color-black;
                            }
                        }

                        svg {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: .8rem;
                        }
                    }
                }

                .projects-grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2.5rem;


                    .project-card {
                        .project-thumbnail {
                            width: 20rem;
                            height: auto;
                            aspect-ratio: 1/1;
                            object-fit: cover;
                            margin-bottom: 1.6rem;
                        }

                        .development {
                            display: flex;
                            gap: .8rem;
                            margin-bottom: .6rem;

                            img {
                                width: auto;
                                height: 2.5rem;
                                aspect-ratio: 1/1;
                                object-fit: cover;
                            }

                            p {
                                @include title-15;
                                max-width: 12rem;
                            }
                        }

                        .location {
                            .linkout {
                                margin-left: .5rem;
                                text-decoration: underline;
                                display: inline-block;
                            }
                        }
                    }

                }
            }
        }

    }
}