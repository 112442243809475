/* Floorplans should start by showing 4 then gradually show less when the view port resizes*/
.embla.-floorplans {
    position: relative;
    --gap: 3rem;

    .embla__container {
        // gap: var(--gap);
        // margin-right: var(--gap);
    }

    .embla__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 4rem;
        height: auto;
        aspect-ratio: 1/1;
        background-color: $color-black;
        border-radius: 50%;
        @include flex-center;
        cursor: pointer;

        &.-disabled {
            opacity: .3;
            pointer-events: none;
        }

        &--prev {
            left: 1.5rem;
        }

        &--next {
            right: calc(1.5rem + var(--gap));
        }

        svg {
            path {
                stroke: $color-white;
            }
        }

    }

    .embla__slide {
        flex: 0 0 calc(25% - var(--gap));
        margin-right: var(--gap);

        @include media('<lg') {
            flex: 0 0 calc(33.3333% - var(--gap));
        }

        @include media('<md') {
            flex: 0 0 calc(50% - var(--gap));
        }

        @include media('<sm') {
            flex: 0 0 100%;
        }
    }
}