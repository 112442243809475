@import '../../../utility/project/oceanfront-squamish-variables';

@mixin agenda-14 {
    font-family: $font-agenda;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $color-black;
    font-weight: 400;
}

@mixin download-all-btn-oceanfront {
    left: unset;
    top: unset;
    right: unset;
    position: relative;
    background-color: $color-oceanfront-green;
    color: $color-black;
    width: fit-content;
    align-self: flex-end;
    gap: 1.5rem;
    padding: .6rem 2rem;
    font-family: $font-agenda;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    border: 1px solid transparent;

    svg {
        path {
            stroke: $color-black;
            transition: all .5s ease-out;
        }
    }

    @include can-hover {
        border: 1px solid $color-black;
        background-color: transparent;
        color: $color-black;

        svg {
            path {
                stroke: $color-black;
            }
        }
    }

}

.project-page.oceanfront-squamish {

    h2 {}

    h3 {}

    .validation-container {
        width: 100dvw;
        height: 100dvh;
        @include flex-center;

        h1 {
            font-family: $font-gotham;
            font-size: 5rem;
        }
    }


    .sub-title {}

    section {
        background-color: $color-white;
        border-bottom: 0;

        >.title {
            font-family: $font-gotham;
            font-size: 3.5rem;
            font-weight: 100;
            line-height: 4.2rem;
            padding-top: 5.5rem;
            padding-bottom: 3.5rem;
        }
    }

    section:last-of-type {}

    .section-download-btn {}

    .placeholder-card {}

    .landing {
        background-color: $color-white;
        color: $color-black;

        .-desktop {

            .content-left {


                .wrapper {}

                .greeting {

                    .text-group {
                        h2 {
                            font-family: $font-gotham;
                            font-size: 3.5rem;
                            line-height: 4.2rem;
                            margin-bottom: 1.5rem;
                        }

                        p {
                            font-family: $font-agenda;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            font-weight: 300;
                        }
                    }
                }

                .presentation-centre {

                    a {}
                }

                .project-blurb {
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 2.625rem;
                    font-weight: 400;
                }
            }

            .content-right {

                .media {}
            }
        }

        .-mobile {


            .content-top {

                .greeting {

                    .text-group {

                        h2 {
                            font-family: $font-gotham;
                            margin-bottom: 1rem;
                        }

                        p {
                            font-family: $font-gotham;
                        }
                    }
                }
            }

            .content-bottom {

                .presentation-centre {}

                .project-blurb {}

                .project-download-all-btn {}
            }
        }
    }

    // Anchor Overview
    .overview {
        padding-top: 0;
        padding-bottom: 3.7rem;
        border-bottom: 1px solid $color-black;

        >.title {
            width: 100%;
            border-bottom: 1px solid $color-black;
        }

        .overview-details-container {
            border-bottom: 1px solid $color-black;
            padding-bottom: 8.8rem;

            @include media('<md') {
                padding-bottom: 0;
            }

            .overview-detail {
                @include media('<sm') {
                    margin-bottom: 4rem;
                }

                .title {
                    font-family: $font-agenda;
                    font-weight: 400;
                    line-height: 1.8rem;
                    font-size: 1.5rem;
                    letter-spacing: .1rem;
                }

                .description {
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }
            }
        }

        .overview-brand-story {
            padding-top: 3.7rem;

            >.wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-bottom: 5.6rem;

                @include media('<md') {
                    flex-direction: column;
                    padding-bottom: 0;
                }
            }

            h2 {
                font-family: $font-gotham;
                font-size: 3.5rem;
                font-weight: 300;
                line-height: 4.2rem;
                flex: 1;
            }

            p {
                flex: 1;
                font-family: $font-agenda;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .overview-media-container {}
    }

    // ANCHOR Brand
    .brand {
        padding-bottom: 5.3rem;

        >.title-container {
            display: flex;
            margin-bottom: 5.3rem;

            @include media('<sm') {
                flex-direction: column;
            }

            @include media('<md') {
                margin-bottom: 0;
            }

            h2 {
                flex: 1;
                font-family: $font-gotham;
                font-size: 3.5rem;
                line-height: 4.2rem;
            }

            .blurb {
                font-family: $font-agenda;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .container-fluid {
            display: flex;
            flex-direction: column;
        }

        .section-download-btn {
            @include download-all-btn-oceanfront();
            margin-bottom: 7.5rem;

            @include media('<sm') {
                margin-bottom: 0;
            }
        }

        .brand-card {
            .title {
                font-family: $font-agenda;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.8rem;
            }

            .content {
                font-family: $font-gotham;
                font-size: 1.5rem;
                line-height: 2.16rem;
                margin-bottom: 2rem;
            }

            .links {
                display: flex;
                gap: .7rem;
            }
        }
    }

    // ANCHOR Social & Gallery
    .social,
    .gallery {
        padding-bottom: 0;

        .title {
            font-family: $font-gotham;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        // any title that isn't the first one

        .sub-title {
            font-family: $font-agenda;
            font-size: 2rem;
            line-height: 2.4rem;
            letter-spacing: .15rem;
            margin-bottom: 1.5rem;
        }

        .sub-title:not(:first-of-type) {
            margin-top: 3.5rem;
        }

        .gallery-card {
            padding-right: 2.8rem;
            margin-bottom: 1.5rem;

            @include media('<md') {
                padding-right: calc(var(--bs-gutter-x) * .5);
            }

            .wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-oceanfront-green;
                aspect-ratio: 1/1;

                >a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;

                    &:hover {
                        .media-download-btn {
                            background-color: $color-oceanfront-orange;

                            svg {
                                path {
                                    stroke: $color-black
                                }
                            }
                        }
                    }
                }
            }

            .media-download-btn {
                position: absolute;
                width: 4.7rem;
                height: auto;
                aspect-ratio: 1/1;
                background-color: $color-white;
                @include flex-center;
                border-radius: 50%;
                top: 1.2rem;
                right: 1.5rem;
                transition: all .3s ease-out;

                svg {
                    path {
                        transition: all .5s ease-out;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.-portrait {
                .wrapper {
                    aspect-ratio: 65/137;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .floorplans {
        padding-top: 0;
        background-color: $color-light-blue;
        border-top: 1px solid $color-black;

        .title {
            display: block;
            font-family: $font-gotham;
            font-size: 3.5rem;
            line-height: 4.2rem;

            span {
                font-family: $font-agenda;
                font-weight: 100;
                font-size: 3.9rem;
                line-height: 4.68rem;
                margin-left: 1.2rem;
                text-transform: capitalize;
            }
        }

        .section-download-btn {
            @include download-all-btn-oceanfront();
        }

        .filter-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 1.5rem;
            margin-bottom: 3rem;


            .filter-groups {
                display: flex;
                gap: 1.6rem;

                @include media('<md') {
                    flex-direction: column;
                    // row-gap: 1.6rem;
                    margin-bottom: 1.5rem;
                }

                @include media('<sm') {
                    display: none;
                }

                .filter-group {
                    p {
                        font-family: $font-agenda;
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        margin-bottom: 1.6rem;
                    }

                    .filters {
                        display: flex;
                        gap: 1rem;

                        button {
                            background-color: $color-white;
                            color: $color-black;
                            padding: .7rem 2.8rem;
                            transition: .25s ease-in-out;

                            &.active {
                                background-color: $color-black;
                                color: $color-white;
                            }
                        }
                    }
                }
            }

            .filter-container.-mobile {
                display: block;
                width: 100%;
                flex: 1 0 100%;

                label {
                    width: 100%;
                    display: block;
                    margin-bottom: 1.25rem;
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem
                }

                select {
                    @include agenda-14;
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    background-color: $color-black;
                    color: $color-white;
                    border: none;
                    outline: none;
                    padding: 1.25rem 2rem;
                    padding-right: 10rem;
                    appearance: none;
                    background-repeat: no-repeat;
                    background-position: 95% 50%;
                    background-size: 1.75rem;
                    margin-bottom: 1.5rem;
                }

                @include media('>sm') {
                    display: none;
                }
            }
        }

        .floorplans-container {

            .no-plans-found {
                text-align: center;

                h2 {
                    margin-top: 3rem;
                    font-family: $font-gotham;
                }
            }

            .floorplan-card {
                margin-bottom: 3rem;

                .wrapper {
                    padding-top: 2.8rem;
                    padding-bottom: 2.6rem;
                    padding-left: 3.2rem;
                    padding-right: 2.3rem;
                    background-color: $color-white;
                }

                .details-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: 3.5rem;
                    font-family: $font-agenda;

                    .specs {
                        font-size: 1.5rem;
                        line-height: 1.8rem;
                        text-transform: capitalize;
                    }

                    .plan {
                        font-size: 3rem;
                        line-height: 3.6rem;
                    }
                }

                .media {
                    text-align: center;

                    img {
                        aspect-ratio: 1/1;
                        max-height: 26rem;
                        margin-bottom: 4.5rem;
                    }
                }


                .links {
                    display: flex;
                    justify-content: center;
                    gap: .8rem;
                }
            }
        }
    }

    .offering {
        padding-bottom: 6.3rem;

        .title {
            font-family: $font-gotham;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }

        .table-container {
            padding-top: 0;
            padding-left: 2.5rem;
        }

        .single-project-accordion {
            .wrapper {
                padding: 1.6rem 2.6rem 2.2rem 0;
            }

            .trigger {
                font-family: $font-agenda;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: .1rem;
                text-transform: uppercase;
                font-weight: 400;
            }

            .text {
                font-family: $font-agenda;
                font-size: 1.5rem;
                line-height: 1.8rem;
                margin-bottom: 2.3rem;
            }
        }
    }

    .single-project-page-footer {
        background-color: transparent;

        .top-banner {
            display: flex;
            justify-content: space-between;
            padding-right: 3.8rem;

            @include media('<sm') {
                flex-wrap: wrap;
                padding-right: 0;
            }

            .left-content.-desktop {
                display: flex;

                @include media('<md') {
                    display: none;
                }

                .media {
                    width: 22rem;
                    height: auto;
                    position: relative;

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 65%;
                        height: auto;
                        object-fit: contain;
                    }
                }

                .multi-media {
                    display: flex;
                    flex-direction: column;

                    .media-row {
                        flex: 1;
                        display: flex;
                    }

                    .mini-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 11rem;
                        height: auto;
                        aspect-ratio: 1/1;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }


            .left-content.-mobile {
                display: none;
                width: 100%;

                @include media('<md') {
                    display: block;
                }

                .media-row {
                    display: flex;
                    width: 100%;

                    .mini-box {
                        position: relative;
                        flex: 1;
                        aspect-ratio: 1/1;
                        height: auto;
                        width: 100%;

                        img {
                            @include abs-center;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .mini-box.-nested {
                        position: relative;

                        img {
                            @include abs-center;
                            width: 75%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }


            }

            .right-content {
                padding-top: 3.2rem;
                padding-bottom: 5.3rem;
                color: #222222;

                @include media('<sm') {
                    padding: 2rem;
                    padding-bottom: 3.2rem;
                }

                h3 {
                    font-family: $font-agenda;
                    font-size: 3.2rem;
                    line-height: 4rem;
                    font-weight: 300;
                    max-width: 35.4rem;
                    text-align: right;
                    margin-bottom: 1.8rem;

                    @include media('<sm') {
                        text-align: left;
                    }
                }

                a {
                    font-family: $font-agenda;
                    font-weight: 500;
                    line-height: 1.92rem;
                    font-size: 1.6rem;
                    letter-spacing: .3rem;
                    text-align: right;
                    display: block;
                    text-decoration: underline;
                    text-underline-offset: .4rem;

                    @include media('<sm') {
                        text-align: left;
                    }
                }
            }
        }


        .bottom-banner {
            background-color: $color-black;

            .wrapper {
                padding-top: 4.1rem;
                padding-bottom: 3.6rem;
            }

            .top-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                @include media('<md') {
                    flex-direction: column;
                    justify-content: flex-start;
                }

                .links {
                    display: flex;
                    gap: 4rem;

                    .link-group {
                        a {
                            font-family: $font-agenda;
                            font-size: 1.6rem;
                            line-height: 2rem;
                            color: $color-white;
                            display: block;
                            margin-bottom: .8rem;
                            transition: all .3s ease-out;

                            @include can-hover {
                                color: $color-oceanfront-red;
                            }
                        }
                    }
                }

                .details-container {
                    display: flex;

                    @include media('<md') {
                        padding-top: 2.5rem;
                        flex-wrap: wrap;
                    }

                    .group {
                        margin-right: 14.2rem;

                        @include media('<md') {
                            margin-right: 5.2rem;
                        }

                        .title {
                            font-family: $font-agenda;
                            font-size: 1.6rem;
                            line-height: 3.2rem;
                            letter-spacing: .2rem;
                            margin-bottom: 0;
                            text-transform: uppercase;
                        }

                        .detail {
                            display: block;
                            margin-bottom: 1rem;
                            font-family: $font-agenda;
                            line-height: 2.6rem;
                            font-size: 1.6rem;
                            letter-spacing: .1rem;
                        }

                        a.detail {
                            transition: all .3s ease-out;

                            @include can-hover {
                                color: $color-oceanfront-red;
                            }
                        }
                    }

                    .group:nth-of-type(2) {
                        margin-right: 6.8rem;
                    }

                    .media {
                        width: 17.2rem;
                        height: 12.6rem;

                        @include media('<md') {
                            width: 30rem;
                            height: auto;
                            margin-top: 2.5rem;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .bottom-container {
                display: flex;
                justify-content: space-between;
                padding-top: 4.3rem;

                @include media('<md') {
                    flex-direction: column;
                }

                .logos {
                    display: flex;
                    gap: 4.1rem;
                    max-width: 40.9rem;

                    @include media('<md') {
                        flex-wrap: wrap;
                        gap: 2rem;
                        margin-bottom: 2rem;
                    }

                    img {
                        width: 26.5rem;
                        height: 3rem;
                        object-fit: contain;
                    }

                    img:last-of-type {
                        width: 10.3rem;
                        height: 2.6rem;
                        object-fit: contain;
                    }
                }

                .fine-print {
                    max-width: 50rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    @include media('<md') {
                        align-items: flex-start;
                        padding-top: 1.5rem;
                    }

                    .linkouts {
                        margin-bottom: .6rem;
                        display: flex;
                        gap: .5rem;

                        a,
                        span {
                            font-family: $font-agenda;
                            color: #757575;
                            display: inline-block;
                            font-size: 1rem;
                            line-height: 1.2rem;
                            transition: all .3s ease-out;

                            @include can-hover {
                                color: $color-white;
                            }
                        }
                    }

                    p {
                        color: #757575;
                        font-family: $font-agenda;
                        font-size: 1rem;
                        line-height: 1.2rem;
                        text-align: right;

                        @include media('<md') {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

}