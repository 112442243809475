// colors
$color-olia-red: #D70B02;
$color-olia-blue: #95C4E0;

/* 
Font weights
400
500
600
700
*/

// fonts
$font-olia-neue-haas-grotesk: "neue-haas-grotesk-display",
sans-serif;