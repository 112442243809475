.single-project-view-component {
    padding-top: 5.7rem;
    flex: 1;
    max-height: inherit;
    overflow-y: auto;

    .title-group {
        display: flex;
        padding-left: 4.3rem;
        gap: 1.6rem;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .project-name {
            @include title-25;
            font-weight: 600;
        }
    }

    .editable-section {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding: 3.2rem 4.3rem;
        border-top: 1px solid $color-border-gray;
        border-bottom: 1px solid $color-border-gray;

        [class*=col] {
            margin-bottom: 2rem;
        }

        .title {
            @include title-20;
            margin-bottom: 2rem;
        }

        .content-image-container {
            width: 100%;
            height: 100%;
            position: relative;
            aspect-ratio: 1/1;
            max-height: 30rem;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: gray;
            }

            button {
                position: absolute;
                right: 2rem;
                top: 2rem;
                background-color: $color-black;
                border-radius: 50%;
                width: 3.5rem;
                height: auto;
                aspect-ratio: 1/1;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 75%;
                    height: 75%;
                }
            }
        }
    }

    label {
        display: block;
        width: 100%;

        span {
            display: block;
            margin-bottom: .5rem;
        }
    }

    textarea {
        display: block;
        width: 100%;
        min-height: 10rem;
        resize: vertical;
        padding: .5rem 1rem;
    }
}