.inline-input {
    position: relative;

    input {
        width: 100%;
        padding: 1.3rem 2.0rem;
        color: $color-black;
        background-color: $color-light-blue;
        border: none;
        outline: none;
        margin-bottom: 1.5rem;
        @include body-15;

        @include media('<sm') {
            margin-bottom: 1rem;
        }

        &.has-error {
            margin-bottom: 0;
        }

        &::placeholder {
            color: rgb(0, 0, 0, .5);
        }

        &:disabled {
            opacity: .55;
            pointer-events: none !important;
        }
    }

    input:disabled~.show-password {
        opacity: .55;
        pointer-events: none !important;
    }

    .show-password {
        position: absolute;
        right: 1.5rem;
        top: 45%;
        transform: translateY(-50%);
        z-index: 10;

        &.has-error {
            top: 40%;
        }

        svg {
            transform: scale(1.2);
        }
    }

    .error {
        color: red;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
        @include body-12;
    }

}