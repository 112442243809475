.app-login {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: $color-light-blue;

    .wrapper {
        width: 54.5rem;
        padding: 5rem;
        margin-inline: auto;
        background-color: $color-white;
    }

    .login-container {}

    .content-container {}

    .top-group {
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            @include title-25;
        }

        .logo {
            svg {
                fill: $color-black;

                g {
                    fill: inherit;
                }

                path {
                    fill: inherit;
                }
            }
        }
    }

}