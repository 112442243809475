@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin abs-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin can-hover {

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }

    &:active {
        @content
    }
}