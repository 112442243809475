/* 

Agenda Thin
font-family: "agenda", sans-serif;
font-weight: 100;
font-style: normal;

Agenda Light
font-family: "agenda", sans-serif;
font-weight: 300;
font-style: normal;

Agenda Regular
font-family: "agenda", sans-serif;
font-weight: 400;
font-style: normal;

Agenda Medium
font-family: "agenda", sans-serif;
font-weight: 500;
font-style: normal;

Agenda Semibold
font-family: "agenda", sans-serif;
font-weight: 600;
font-style: normal;

Agenda Bold
font-family: "agenda", sans-serif;
font-weight: 700;
font-style: normal;

^ This fond is coming from ADOBE fonts and is enqueued in the head layout for 
^ the specific project
*/

$font-agenda: 'agenda',
sans-serif;

$font-romie: 'Romie',
serif;

$color-mayfair-pink: #DE8E71;
$color-mayfair-blue: #00192F;
$color-au-chico: #9D6660;