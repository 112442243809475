@import '../../utility/project/oceanfront-squamish-variables';

.main-nav.-oceanfront {
    background-color: $color-white;
    color: $color-black;
    padding: 0;

    >.wrapper {}

    .logo {
        a {}

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .links-container {
        justify-content: flex-end;
        padding-right: 5.5rem;

        @include media('<sm') {
            padding-right: 1.9rem;
        }

        button {
            font-family: $font-agenda;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.8rem;
            margin-right: 5.2rem;
            color: inherit;

            &.-btn {
                background: $color-black;
                color: $color-white;
                border: 1px solid transparent;

                &:hover {
                    background: $color-white;
                    color: $color-black;
                    border: 1px solid $color-black;
                }

                &::after {}
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -.2rem;
                transform: translateX(-50%);
                height: 2px;
                width: 0;
                transition: all .3s ease-out;
                background-color: $color-black;
            }

            &.-active {
                &::after {
                    width: 100%;
                }
            }

            &:hover {
                &::after {}
            }
        }

        button:last-child {
            margin-right: 0;
        }

        a:last-of-type {}
    }

    .links-container-mobile {

        @include media('<=md') {}

        .logout-mobile {}
    }

    .buttons-group {

        button {}
    }

    .lang-btn {}

    .notification-btn {}

}