@import '../../../utility/project/mayfair-west-variables';

@mixin agenda-14 {
    font-family: $font-agenda;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $color-black;
    font-weight: 400;
}

@mixin romie-35 {
    font-family: $font-romie;
    font-size: 3.5rem;
    line-height: 5.04rem;
}

@mixin download-all-btn-mayfair {
    left: unset;
    top: unset;
    right: unset;
    position: relative;
    background-color: $color-au-chico;
    color: $color-white;
    width: fit-content;
    align-self: flex-end;
    gap: 1.5rem;
    padding: .6rem 2rem;
    font-family: $font-agenda;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    border: 1px solid transparent;

    svg {

        path {
            stroke: $color-white;
            transition: all .5s ease-out;
        }
    }

    @include can-hover {
        border: 1px solid $color-au-chico;
        background-color: transparent;
        color: $color-au-chico;

        svg {
            path {
                stroke: $color-au-chico;
            }
        }
    }


}

.project-page.mayfairwest {

    h2 {}

    h3 {}

    .validation-container {
        width: 100dvw;
        height: 100dvh;
        @include flex-center;

        h1 {
            font-family: $font-romie;
            font-size: 5rem;
        }
    }


    .sub-title {}

    section {
        background-color: $color-white;

        >.title {
            @include romie-35;
            padding-top: 2.4rem;
            padding-bottom: 3.5rem;
        }
    }

    section:last-of-type {}

    .section-download-btn {}

    .placeholder-card {}

    .landing {
        background-color: $color-mayfair-blue;

        .-desktop {

            .content-left {


                .wrapper {}

                .greeting {

                    .text-group {
                        h2 {
                            font-family: $font-romie;
                            margin-bottom: 1.5rem;
                        }

                        p {
                            font-family: $font-romie;
                        }
                    }
                }

                .presentation-centre {

                    a {}
                }

                .project-blurb {}
            }

            .content-right {

                .media {}
            }
        }

        .-mobile {


            .content-top {

                .greeting {

                    .text-group {

                        h2 {
                            font-family: $font-romie;
                            margin-bottom: 1rem;
                        }

                        p {
                            font-family: $font-romie;
                        }
                    }
                }
            }

            .content-bottom {

                .presentation-centre {}

                .project-blurb {}

                .project-download-all-btn {}
            }
        }
    }

    // Anchor Overview
    .overview {
        border-bottom: 1px solid $color-black;
        padding-bottom: 3.7rem;

        >.title {
            width: 100%;
            border-bottom: 1px solid $color-black;
        }

        .overview-details-container {
            margin-bottom: 8.8rem;

            .overview-detail {

                .title {
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    letter-spacing: .15rem;
                }

                .description {
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }

                @include media('<sm') {
                    margin-bottom: 4rem;
                }
            }
        }

        .overview-media-container {}
    }

    // ANCHOR Brand
    .brand {
        padding-bottom: 5.3rem;

        >.title-container {
            display: flex;
            margin-bottom: 5.3rem;

            @include media('<lg') {
                flex-direction: column;
            }

            h2 {
                flex: 1;
                @include romie-35;
            }

            .blurb {
                font-family: $font-agenda;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;
                max-width: 83.4rem;
            }
        }

        .container-fluid {
            display: flex;
            flex-direction: column;
        }

        .section-download-btn {
            @include download-all-btn-mayfair();
            margin-bottom: 7.5rem;

            @include media('<lg') {
                align-self: flex-start;
            }
        }

        .brand-card {
            .title {
                font-family: $font-agenda;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.8rem;
            }

            .content {
                font-family: $font-romie;
                font-size: 1.5rem;
                line-height: 2.16rem;
                margin-bottom: 2rem;
            }

            .links {
                display: flex;
                gap: .7rem;
            }
        }
    }

    // ANCHOR Social & Gallery
    .social,
    .gallery {
        padding-bottom: 4.4rem;

        .title {
            @include romie-35;
        }

        // any title that isn't the first one

        .sub-title {
            font-family: $font-agenda;
            font-size: 2rem;
            line-height: 2.4rem;
            letter-spacing: .15rem;
            margin-bottom: 1.5rem;
        }

        .sub-title:not(:first-of-type) {
            margin-top: 3.5rem;
        }

        .gallery-card {
            padding-right: 2.8rem;
            margin-bottom: 1.5rem;

            @include media('<md') {
                padding-right: calc(var(--bs-gutter-x) * .5);
            }

            .wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-mayfair-blue;
                aspect-ratio: 1/1;

                >a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;

                    &:hover {
                        .media-download-btn {
                            background-color: $color-au-chico;

                            svg {
                                path {
                                    stroke: $color-white
                                }
                            }
                        }
                    }
                }
            }

            .media-download-btn {
                position: absolute;
                width: 4.7rem;
                height: auto;
                aspect-ratio: 1/1;
                background-color: $color-white;
                @include flex-center;
                border-radius: 50%;
                top: 1.2rem;
                right: 1.5rem;
                transition: all .3s ease-out;

                svg {
                    path {
                        transition: all .5s ease-out;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &.-portrait {
                .wrapper {
                    aspect-ratio: 65/137;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .floorplans {
        .title {
            display: block;
            background-color: $color-au-chico;
            color: $color-white;
            @include romie-35;

            span {
                font-family: $font-agenda;
                font-weight: 100;
                font-size: 3.9rem;
                line-height: 4.68rem;
                margin-left: 1.2rem;
                text-transform: capitalize;
            }
        }

        .section-download-btn {
            @include download-all-btn-mayfair();
        }

        .filter-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 1.5rem;
            margin-bottom: 3rem;

            .filter-type {
                display: flex;
                gap: 4.5rem;

                @include media('<sm') {
                    display: none;
                }

                button {
                    min-width: 7rem;
                    transition: all .3s ease-out;
                    padding-inline: 1.5rem;

                    &.-active {
                        background-color: $color-au-chico;
                        color: $color-white;
                    }
                }
            }

            .filter-type.-mobile {
                display: block;
                width: 100%;
                flex: 1 0 100%;

                label {
                    width: 100%;
                    display: block;
                    margin-bottom: 1.25rem;
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem
                }

                select {
                    @include agenda-14;
                    font-family: $font-agenda;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    background-color: $color-mayfair-blue;
                    color: $color-white;
                    border: none;
                    outline: none;
                    padding: 1.25rem 2rem;
                    padding-right: 10rem;
                    appearance: none;
                    background-repeat: no-repeat;
                    background-position: 95% 50%;
                    background-size: 1.75rem;
                }

                @include media('>sm') {
                    display: none;
                }
            }
        }

        .floorplans-container {
            padding-bottom: 4.2rem;

            .no-plans-found {
                text-align: center;

                h2 {
                    margin-top: 3rem;
                    font-family: $font-romie;
                }
            }

            .floorplan-card {
                margin-bottom: 3rem;

                .wrapper {
                    padding-top: 2.8rem;
                    padding-bottom: 2.6rem;
                    padding-left: 3.2rem;
                    padding-right: 2.3rem;
                    border: 1px solid $color-black;
                }

                .details-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: 2.5rem;
                    font-family: $font-romie;

                    .specs {
                        font-size: 1.5rem;
                        line-height: 2.16rem;
                        text-transform: capitalize;
                    }

                    .plan {
                        font-size: 3rem;
                        line-height: 4.32rem;
                    }
                }

                .media {
                    text-align: center;

                    img {
                        width: 50%;
                        max-height: 23.8rem;
                        margin-bottom: 4.5rem;
                    }
                }


                .links {
                    display: flex;
                    justify-content: center;
                    gap: .8rem;
                }
            }
        }
    }

    .single-project-page-footer {
        background-color: $color-mayfair-blue;
        padding-top: 5.9rem;
        padding-bottom: 3.2rem;
        padding-inline: 5.5rem;

        @include media('<md') {
            padding-inline: 2.8rem;
        }

        @include media('<sm') {
            padding-bottom: 8.2rem;
        }

        .media-group.-desktop {
            display: flex;
            justify-content: space-between;
            gap: 4rem;

            @include media('<sm') {
                display: none;
            }

            .content-left {
                display: flex;
                flex-direction: column;
                row-gap: 12.7rem;

                img {
                    width: 100%;
                }

                .project-logo {
                    width: 20.5rem;
                }

                .developer-logo {
                    width: 19.4rem;
                }
            }

            .content-right {
                .media {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }


        .media-group.-mobile {
            display: none;

            @include media('<sm') {
                display: flex;
                flex-direction: column;
                gap: 4.7rem;
            }

            .logo {
                width: 18rem;
                height: 5.6rem;
            }

            .developer {
                width: 15.7rem;
                height: 6.6rem;
            }
        }

        .disclaimer-partners-group {
            margin-top: 6.8rem;
            display: flex;
            justify-content: space-between;

            @include media('<lg') {
                flex-direction: column-reverse;
                margin-top: 3.3rem;
            }

            .disclaimer {
                max-width: 83rem;
                opacity: 0.5;
                @include agenda-14;
                letter-spacing: 0;
                margin-bottom: 1.6rem;
                color: $color-white; //calling color down here because I got lazy and added color to mixins
                font-size: min(1.4rem, 14px);

                @include media('<sm') {
                    font-size: min(1.3rem, 13px);
                }
            }

            .content-left {
                display: block;

                .copyright {
                    color: $color-white;
                    display: flex;
                    align-items: center;

                    p {
                        @include agenda-14;
                        opacity: 0.5;
                        margin-right: 4.1rem;
                        color: inherit;
                        font-size: min(1.4rem, 14px);

                        @include media('<sm') {
                            letter-spacing: 0;
                            font-size: min(1.3rem, 13px);
                        }
                    }

                    a {
                        @include agenda-14;
                        opacity: 0.5;
                        text-decoration: none;
                        transition: all 0.3s ease-out;
                        color: inherit;
                        font-size: min(1.4rem, 14px);

                        &:hover {
                            opacity: 1;
                        }

                        @include media('<sm') {
                            font-size: min(1.3rem, 13px);
                        }
                    }

                    .links {
                        display: flex;
                        align-items: center;
                        gap: 0.8rem;

                        @include media('<sm') {
                            letter-spacing: 0;
                        }

                        span {
                            width: 1px;
                            height: 2rem;
                            background-color: #707070;
                        }
                    }
                }
            }

            .content-right {
                display: flex;
                gap: 3rem;

                @include media('<lg') {
                    margin-bottom: 2.7rem;
                }

                .partner-group {
                    @include media('<sm') {
                        flex: 0 1 33.3333%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .text {
                        @include agenda-14;
                        min-height: 1.8rem;
                        margin-bottom: 1.1rem;
                        opacity: 0.5;
                        white-space: nowrap;
                        color: $color-white;

                        @include media('<sm') {
                            letter-spacing: 0;
                        }
                    }

                    img {
                        object-fit: contain;
                        width: 100%;

                        @include media('<sm') {
                            max-height: 3.5rem;
                        }
                    }
                }

                .partner-group:first-child {
                    img {
                        transform: translateY(0.5rem);

                        @include media('<sm') {
                            transform: unset;
                        }
                    }
                }

                .partner-group:last-child {
                    img {
                        transform: translateY(2rem);

                        @include media('<sm') {
                            transform: unset;
                        }
                    }
                }
            }
        }

    }
}