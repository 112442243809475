.single-project-nav-mobile {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1.4rem 0 2rem 0;
    background-color: $color-white;
    z-index: 100;
    -webkit-box-shadow: 0px 2px 16px 5px rgba(0, 0, 0, .25);
    box-shadow: 0px 2px 16px 5px rgba(0, 0, 0, .25);

    @include media('>md') {
        display: none;
    }

    .wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 2.6rem;
    }

    .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include body-12-medium;

        span {
            opacity: .6;
            transition: all .3s ease-in-out;
        }

        svg {
            opacity: .4;
            transition: all .3s ease-in-out;
        }

        .logo {
            width: 3.5rem;
            height: auto;
            aspect-ratio: 1/1;
            margin-bottom: .6rem;
            transition: all .3s ease-out;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &.-active {
            span {
                opacity: 1
            }

            svg {
                opacity: 1;
            }
        }
    }
}