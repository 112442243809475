.toast-container {
    position: fixed;
    right: 2rem;
    top: 8rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 1.6rem;

    @include media('<md') {
        right: 0;
    }
}