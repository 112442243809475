.global-project-view-component {
    width: 100%;
    overflow: auto;
    max-height: inherit;

    .components-not-found {
        padding-top: 3.7rem;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        @include title-15;
        text-decoration: underline;
    }

    .project-card {
        padding-top: 3.7rem;
        padding-left: 3.2rem;
        padding-bottom: 3.2rem;
        display: flex;
        border-bottom: 1px solid $color-border-gray;

        .thumbnail {
            width: 20rem;
            height: auto;
            aspect-ratio: 1/1;
            margin-right: 3.2rem;
            background-color: lightgray;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .text-group {
            padding-top: .8rem;
        }

        .title {
            @include title-15;
            font-weight: 600;
            margin-bottom: .5rem;
        }

        .sub {
            @include title-12;
            font-weight: 500;
            margin-bottom: 1.1rem;
        }

        .sub:last-of-type {
            margin-bottom: 6.7rem;
        }

        .magic-link {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: $color-magic-link;
            @include title-12;
            font-weight: 500;
        }
    }
}